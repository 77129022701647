import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin } from 'antd';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { fetchRawProjectSummeryList } from '../../../redux/projectMargin/action';
import get from 'lodash/get';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import axiosConfig from './axiosConfig';
import { cellFormatter, CustomLoadingCellRenderer } from '../Uitiles';
import { GetRefreshInfo } from '../../../redux/FinanceMapping/saga';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;

const RawSummery = (props) => {
    const { onFetchRawProjectSummeryList, rawProjectSummeryList, isRawProjectSummeryListLoading } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(60000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const [columnDefs, setColumnDefs] = useState([]);

    const [refreshInfo, setRefreshInfo] = useState(null);


    const fetchInfo = async (key) => {
        try {
            const info = await GetRefreshInfo(key);
            console.log('Refresh Time', info)
            if (info.Date_Time) {
                setRefreshInfo(info.Date_Time)
            }
        } catch (error) {
            console.error("Error fetching info:", error);
        }
    };
    
    const generateColumns = async (columnList) => {
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'totalCount') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly

                valueFormatter: (params) => {
                    // Check if the column is a date column
                    const isDateColumn = ['PlanRfrom', 'PlanR_to', 'Capdate', 'EquipCrDt', 'RetDate', 'StrtDate'].includes(key);

                    if (isDateColumn) {
                        // Validate and format the date
                        const date = moment(params.value); // Parse date with moment.js
                        return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                    }
                    else {

                        const isDateColumn1 = ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key);

                        if (isDateColumn1) {
                            return Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                    }
                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: {

                    textAlign: ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key) ? 'right' : 'left'

                }, // Cell alignment
                editable: true, // Enable editing for cells
                filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));

        // Debugging output for generated columns
        console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };
    
    useEffect(() => {
        if(rawProjectSummeryList.length==0 && !isRawProjectSummeryListLoading){
            onFetchRawProjectSummeryList({ tableName: 'CRC_PM_FBL3N_CONSOL', pageNumber: pageNumber, pageSize: pageSize });
        }
    }, [])

    const fetchAllData = async (totalPages) => {
        console.log("fetch summery api called")
        setLoadingRows(true); // Set loading state to true while fetching
        try {
            const allDataPromises = Array.from({ length: totalPages - 1 }, (_, i) => i + 2).map(pageNum =>
                axiosConfig.get(`/GeProcesstList?Table_Name=Project_Summary_Consol&pageNumber=${pageNum}&pageSize=${pageSize}`)
            );
            const allResponses = await Promise.all(allDataPromises);
            const allData = allResponses.flatMap(res => res.data?.data || []);
            setRowData(prevData => [...(prevData || []), ...allData]);
        } catch (error) {
            console.error('Error fetching additional data:', error);
        } finally {
            setLoadingRows(false); // Set loading state to false after fetching
        }
    };

    // Check for changes in rawProjectSummeryList and fetch more data if needed
    useEffect(() => {
        if (rawProjectSummeryList.length > 0) {
            generateColumns(rawProjectSummeryList[0]);
            setRowData(rawProjectSummeryList);
            fetchInfo('CRC_PM_FBL3N_CONSOL')
            const totalCount = rawProjectSummeryList[0]?.totalCount || 0; // Get total count from raw data
            const totalPage = Math.ceil(totalCount / pageSize); // Calculate total pages
            // fetchAllData(totalPage); // Fetch additional data
        }
    }, [rawProjectSummeryList]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const columns = sheet[0].map((header, index) => ({
                field: header,
                editable: true,
                filter: true,
                enableValue: header !== 'name' && header !== 'id',
                rowDrag: index === 0 ? true : false
            }));

            const rows = sheet.slice(1).map((row) =>
                row.reduce((acc, value, index) => {
                    acc[sheet[0][index]] = value;
                    return acc;
                }, {})
            );

            setColumnDefs(columns);
            // setRowData(rows);
        };
        reader.readAsBinaryString(file);
    };

    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    //   useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef,currentTab,layouts,setLayoutSave);
    //         })();
           
    //     }
      

    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef)
    //     {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab,);
    //             setLayoutLoad(false)
    //         })();
           
            
    //     }
    // }, [layoutLoad])

   

    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        };
    }, []);
      const currentTab = 'CRC_PM_FBL3N_CONSOL'
    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [currentTab]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [currentTab]); 

    const rowSelection = useMemo(() => {
        return { mode: "multiRow" };
    }, []);

    const onGridReady = useCallback((params) => {
        if (isRawProjectSummeryListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (isRawProjectSummeryListLoading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loadingRows) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loadingRows]);

    // Custom status panel component
    const CustomStatusPanel = ({ refreshInfo }) => {
        return <div style={{ paddingTop: '20px' }}>{refreshInfo}</div>;
    };


    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: () => <CustomStatusPanel refreshInfo={refreshInfo} />, // React component
                align: 'right',
            },
        ],
    };
    
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    return (
        <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', position: 'relative' }}>
            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowDragManaged={true}
                    onRowDragEnd={onRowDragEnd}
                    defaultColDef={defaultColDef}
                    rowSelection={rowSelection}
                    onGridReady={onGridReady}
                    sideBar={true}
                    //
                    pivotPanelShow={"always"}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    onSelectionChanged={onSelectionChanged}
                    // enableFilter={true}
                    statusBar={statusBar}
                    rowHeight={40}
                    headerHeight={50}
                    defaultExcelExportParams={defaultExcelExportParams}
                    defaultCsvExportParams={defaultCsvExportParams}
                    loadingOverlayComponent={CustomLoadingCellRenderer}
                    loadingOverlayComponentParams={{
                        loadingMessage: "Loading all rows...",
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ projectMarginReducer }) => {
    const isRawProjectSummeryListLoading = get(projectMarginReducer, 'isRawProjectSummeryListLoading', false);
    const rawProjectSummeryList = get(projectMarginReducer, 'rawProjectSummeryList', []);

    return {
        isRawProjectSummeryListLoading,
        rawProjectSummeryList,
    }
}

const mapDispatchToProps = {
    onFetchRawProjectSummeryList: fetchRawProjectSummeryList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RawSummery);
