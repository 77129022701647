export let countries_List = [
    { 'country': "ALGERIA" },
    { 'country': "AMERICAN SAMOA" },
    { 'country': "ANDORRA" },
    { 'country': "ANGOLA" },
    { 'country': "ANGUILLA" },
    { 'country': "ANTIGUA" },
    { 'country': "ARGENTINA" },
    { 'country': "ARMENIA" },
    { 'country': "ARUBA" },
    { 'country': "AUSTRALIA" },
    { 'country': "AUSTRIA" },
    { 'country': "AZERBAIJAN" },
    { 'country': "BAHAMAS" },
    { 'country': "BAHRAIN" },
    { 'country': "BANGLADESH" },
    { 'country': "BARBADOS" },
    { 'country': "BELARUS" },
    { 'country': "BELGIUM" },
    { 'country': "BELIZE" },
    { 'country': "BENIN" },
    { 'country': "BERMUDA" },
    { 'country': "BHUTAN" },
    { 'country': "BOLIVIA" },
    { 'country': "BONAIRE" },
    { 'country': "BOSNIA AND HERZEGOVINA" },
    { 'country': "BOTSWANA" },
    { 'country': "BRAZIL" },
    { 'country': "BRUNEI" },
    { 'country': "BULGARIA" },
    { 'country': "BURKINA FASO" },
    { 'country': "BURUNDI" },
    { 'country': "CAMEROON" },
    { 'country': "CANADA" },
    { 'country': "CANARY ISLANDS" },
    { 'country': "CAPE VERDE" },
    { 'country': "CAYMAN ISLANDS" },
    { 'country': "CENTRAL AFRICAN REPUBLIC" },
    { 'country': "CHAD" },
    { 'country': "CHANNEL ISLANDS" },
    { 'country': "CHILE" },
    { 'country': "CHINA" },
    { 'country': "COLOMBIA" },
    { 'country': "COMORES" },
    { 'country': "CONGO" },
    { 'country': "COOK ISLANDS" },
    { 'country': "COSTA RICA" },
    { 'country': "CROATIA" },
    { 'country': "CUBA" },
    { 'country': "CURACAO" },
    { 'country': "CYPRUS" },
    { 'country': "CZECH REPUBLIC" },
    { 'country': "DENMARK" },
    { 'country': "DJIBOUTI" },
    { 'country': "DOMINICA" },
    { 'country': "DOMINICAN REPUBLIC" },
    { 'country': "ECUADOR" },
    { 'country': "EGYPT" },
    { 'country': "EL SALVADOR" },
    { 'country': "EQUATORIAL GUINEA" },
    { 'country': "ERITREA" },
    { 'country': "ESTONIA" },
    { 'country': "ETHIOPIA" },
    { 'country': "FAROE ISLANDS" },
    { 'country': "FIJI ISLANDS" },
    { 'country': "FINLAND" },
    { 'country': "FRANCE" },
    { 'country': "FRENCH GUYANA" },
    { 'country': "GABON" },
    { 'country': "GAMBIA" },
    { 'country': "GEORGIA" },
    { 'country': "GERMANY" },
    { 'country': "GHANA" },
    { 'country': "GIBRALTAR" },
    { 'country': "GREECE" },
    { 'country': "GREENLAND" },
    { 'country': "GRENADA" },
    { 'country': "GUADELOUPE" },
    { 'country': "GUAM" },
    { 'country': "GUATEMALA" },
    { 'country': "GUINEA BISSAU" },
    { 'country': "GUINEA REPUBLIC" },
    { 'country': "GUYANA" },
    { 'country': "HAITI" },
    { 'country': "HOLLAND" },
    { 'country': "HONDURAS" },
    { 'country': "HONG KONG" },
    { 'country': "HUNGARY" },
    { 'country': "ICELAND" },
    { 'country': "INDIA" },
    { 'country': "INDONESIA" },
    { 'country': "IRAN" },
    { 'country': "IRAQ" },
    { 'country': "IRELAND" },
    { 'country': "ISRAEL" },
    { 'country': "ITALY" },
    { 'country': "IVORY COAST" },
    { 'country': "JAMAICA" },
    { 'country': "JAPAN" },
    { 'country': "JORDAN" },
    { 'country': "KAMPUCHEA" },
    { 'country': "KAZAKHSTAN" },
    { 'country': "KENYA" },
    { 'country': "KIRIBATI" },
    { 'country': "KUWAIT" },
    { 'country': "KYRGYZSTAN" },
    { 'country': "LAOS" },
    { 'country': "LATVIA (LETLAND)" },
    { 'country': "LEBANON" },
    { 'country': "LESOTHO" },
    { 'country': "LIBERIA" },
    { 'country': "LIBYA" },
    { 'country': "LIECHTENSTEIN" },
    { 'country': "LITHUANIA" },
    { 'country': "LUXEMBOURG" },
    { 'country': "MACAU" },
    { 'country': "MADAGASCAR" },
    { 'country': "MALAWI" },
    { 'country': "MALAYSIA" },
    { 'country': "MALDIVES" },
    { 'country': "MALI" },
    { 'country': "MALTA" },
    { 'country': "MARSHALL ISLANDS" },
    { 'country': "MARTINIQUE" },
    { 'country': "MAURITANIA" },
    { 'country': "MAURITIUS" },
    { 'country': "MEXICO" },
    { 'country': "MOLDOVA" },
    { 'country': "MONACO" },
    { 'country': "MONGOLIA" },
    { 'country': "MONTSERRAT" },
    { 'country': "MOROCCO" },
    { 'country': "MOZAMBIQUE" },
    { 'country': "MYANMAR (BIRMA)" },
    { 'country': "NAMIBIA" },
    { 'country': "NAURU" },
    { 'country': "NEPAL" },
    { 'country': "NEVIS, ISLAND OF" },
    { 'country': "NEW CALEDONIA" },
    { 'country': "NEW ZEALAND" },
    { 'country': "NICARAGUA" },
    { 'country': "NIGER" },
    { 'country': "NIGERIA" },
    { 'country': "NIUE ISLAND" },
    { 'country': "NORWAY" },
    { 'country': "OMAN" },
    { 'country': "PAKISTAN" },
    { 'country': "PANAMA" },
    { 'country': "PAPUA NEW GUINEA" },
    { 'country': "PARAGUAY" },
    { 'country': "PERU" },
    { 'country': "PHILIPPINES" },
    { 'country': "POLAND" },
    { 'country': "PORTUGAL" },
    { 'country': "PUERTO RICO" },
    { 'country': "QATAR" },
    { 'country': "REUNION, ISLAND OF" },
    { 'country': "ROMANIA" },
    { 'country': "RUSSIA" },
    { 'country': "RWANDA" },
    { 'country': "SABA" },
    { 'country': "SAKHALIN ISLANDS" },
    { 'country': "SAO TOME & PRINICPE" },
    { 'country': "SAUDI ARABIA" },
    { 'country': "SCOTLAND" },
    { 'country': "SENEGAL" },
    { 'country': "SERBIA" },
    { 'country': "SEYCHELLES" },
    { 'country': "SIERRA LEONE" },
    { 'country': "SINGAPORE" },
    { 'country': "SLOVAKIA" },
    { 'country': "SLOVENIA" },
    { 'country': "SOLOMON ISLANDS" },
    { 'country': "SOMALIA" },
    { 'country': "SOUTH AFRICA" },
    { 'country': "SOUTH KOREA" },
    { 'country': "SPAIN" },
    { 'country': "SRI LANKA" },
    { 'country': "ST. BARTHELEMY" },
    { 'country': "ST. EUSTATIUS" },
    { 'country': "ST. KITTS" },
    { 'country': "ST. LUCIA" },
    { 'country': "ST. MAARTEN" },
    { 'country': "ST. VINCENT" },
    { 'country': "SUDAN" },
    { 'country': "SURINAME" },
    { 'country': "SWAZILAND" },
    { 'country': "SWEDEN" },
    { 'country': "SWITZERLAND" },
    { 'country': "SYRIA" },
    { 'country': "TAHITI" },
    { 'country': "TAIWAN" },
    { 'country': "TAJIKISTAN" },
    { 'country': "TANZANIA" },
    { 'country': "THAILAND" },
    { 'country': "TOGO" },
    { 'country': "TONGA" },
    { 'country': "TRINIDAD & TOBAGO" },
    { 'country': "TUNISIA" },
    { 'country': "TURKEY" },
    { 'country': "TURKMENISTAN" },
    { 'country': "TURKS & CAICOS ISLANDS" },
    { 'country': "TUVALU" },
    { 'country': "U.A.E." },
    { 'country': "UGANDA" },
    { 'country': "UK" },
    { 'country': "UKRAINE" },
    { 'country': "URUGUAY" },
    { 'country': "USA" },
    { 'country': "UZBEKISTAN" },
    { 'country': "VANUATU" },
    { 'country': "VENEZUELA" },
    { 'country': "VIETNAM" },
    { 'country': "VIRGIN ISLANDS, BRITISH" },
    { 'country': "VIRGIN ISLANDS, USA" },
    { 'country': "WESTERN SAMOA" },
    { 'country': "YEMEN" },
    { 'country': "ZAIRE" },
    { 'country': "ZIMBABWE" }
  ];
  
  export let diameters = [
    { "diameter": 2 },
    { "diameter": 3 },
    { "diameter": 4 },
    { "diameter": 6 },
    { "diameter": 7 },
    { "diameter": 9 },
    { "diameter": 8 },
    { "diameter": 10 },
    { "diameter": 11 },
    { "diameter": 12 },
    { "diameter": 13 },
    { "diameter": 14 },
    { "diameter": 15 },
    { "diameter": 16 },
    { "diameter": 18 },
    { "diameter": 20 },
    { "diameter": 22 },
    { "diameter": 24 },
    { "diameter": 26 },
    { "diameter": 28 },
    { "diameter": 30 },
    { "diameter": 32 },
    { "diameter": 34 },
    { "diameter": 35 },
    { "diameter": 36 },
    { "diameter": 38 },
    { "diameter": 40 },
    { "diameter": 42 },
    { "diameter": 44 },
    { "diameter": 46 },
    { "diameter": 48 },
    { "diameter": 50 },
    { "diameter": 52 },
    { "diameter": 54 },
    { "diameter": 56 },
    { "diameter": 58 },
    { "diameter": 60 },
    { "diameter": 64 },
    { "diameter": 100 },
    { "diameter": 120 },
    
  ];
  export  let saleContracts = [
    { sale_contract: "M-300" },
    { sale_contract: "M-220" },
    { sale_contract: "P-200" },
    { sale_contract: "P-260" },
    { sale_contract: "P-600" },
    { sale_contract: "PFM" },
    { sale_contract: "Wire" },
    { sale_contract: "P-450" },
    { sale_contract: "PFM/P600" },
    { sale_contract: "IWM" },
    { sale_contract: "P260/P600" },
    { sale_contract: "PFM/P260/P600/Wire" },
    { sale_contract: "Parts etc." },
    { sale_contract: "Various" },
    { sale_contract: "P625" }
  ];
  export let types = [
    { type: "Onshore" },
    { type: "Offshore" },
    { type: "Offshore J Lay" },
    { type: "Spoolbase" },
    { type: "Small dia" },
    { type: "Fabrication" },
    { type: "Various" }
  ];
  
  export let rootBeadTypes = [
    { root_bead_type: "IWM" },
    { root_bead_type: "External w/ Copper" },
    { root_bead_type: "External w/ STT" },
    { root_bead_type: "External Purge" },
    { root_bead_type: "Manual" },
    { root_bead_type: "Manual STT" },
    { root_bead_type: "AW STT" },
    { root_bead_type: "Internal" },
    { root_bead_type: "NA" },
    { root_bead_type: "TBA" },
    { root_bead_type: "External w/ Copper/Internal" },
    { root_bead_type: "CMT" },
    { root_bead_type: "IW" }
  ];
  
  export  let externalEquipments = [
    { external_equipment: "P600" },
    { external_equipment: "P200" },
    { external_equipment: "P250" },
    { external_equipment: "P260" },
    { external_equipment: "M300B" },
    { external_equipment: "M300C" },
    { external_equipment: "NA" },
    { external_equipment: "P600/P200" },
    { external_equipment: "P600/P260" },
    { external_equipment: "M300" },
    { external_equipment: "P450" },
    { external_equipment: "P450/P600" },
    { external_equipment: "P260/P450/P600" },
    { external_equipment: "Other" },
    { external_equipment: "Various" },
    { external_equipment: "P625" },
    { external_equipment: "P260/P450/M300" },
    { external_equipment: "P260/M300" },
    { external_equipment: "P600/M300" },
    { external_equipment: "CMT/P625" },
    { external_equipment: "CBUC/P625" },
    { external_equipment: "STT/GSFCAW" }
  ];
  
  export let weldingProcesses = [
    { welding_process: "GMAW" },
    { welding_process: "PGMAW" },
    { welding_process: "FCAW" },
    { welding_process: "Tandem Wire" },
    { welding_process: "G + FCAW" },
    { welding_process: "NA" },
    { welding_process: "Other" },
    { welding_process: "GTAW" },
    { welding_process: "GTAW/GSFCAW" },
    { welding_process: "STT/GSFCAW" },
    { welding_process: "GTAW/GSCAW&P-GMAW" },
    { welding_process: "CTM Root/P-GMAW" },
    { welding_process: "Auto-GTAW" },
    { welding_process: "CMT/P-GMAW" },
    { welding_process: "GMAW / PGMAW" },
    { welding_process: "P+GMAW" },
    { welding_process: "G + PCAW" }
  ];
  
  export let powerSupplies = [
    { power_supply: "350Pro" },
    { power_supply: "456" },
    { power_supply: "456/Fronius 3200" },
    { power_supply: "CDT-450" },
    { power_supply: "CDT-450 Modification" },
    { power_supply: "DC400" },
    { power_supply: "DC400/350Pro" },
    { power_supply: "Fronius" },
    { power_supply: "Fronius 3200" },
    { power_supply: "Lincoln SA 200 / Miller Pipe Pro" },
    { power_supply: "Miller 304" },
    { power_supply: "Miller 304/Fronius 3200" },
    { power_supply: "NA" },
    { power_supply: "TBA" },
    { power_supply: "XMT 300" },
    { power_supply: "XMT 300/Fronius 3200" }
  ];
  export  let wireTypes = [
    { wireType: "TS6" },
    { wireType: "K-600" },
    { wireType: "Clad" },
    { wireType: "22-09" },
    { wireType: "25-09" },
    { wireType: "FCAW" },
    { wireType: "NA" },
    { wireType: "TBA" },
    { wireType: "NiMo 80" },
    { wireType: "QA6" },
    { wireType: "TS6/K-600" },
    { wireType: "TS6/K-600/Nimo" },
    { wireType: "TS6/QA6" },
    { wireType: "22-09/25-09" },
    { wireType: "TS6/Nimo" },
    { wireType: "L56" },
    { wireType: "625" },
    { wireType: "K-Ni" },
    { wireType: "CRC70SG" },
    { wireType: "Lincoln" },
    { wireType: "K Nova Ni" },
    { wireType: "Super Arc L-56" }
  ];
  
  
  export let terrains = [
    { terrain: "Mountains" },
    { terrain: "Flats" },
    { terrain: "Swamp" },
    { terrain: "Rolling" },
    { terrain: "Normal" },
    { terrain: "NA" },
    { terrain: "Rolling to flat" },
    { terrain: "Dessert" },
    { terrain: "Hills" }
  ];
  
  export let climates = [
    { climate: "Dessert" },
    { climate: "Tropical" },
    { climate: "Artic" },
    { climate: "Monsoon" },
    { climate: "Normal" },
    { climate: "NA" }
  ];
  export let positions = [
    { position: "1G" },
    { position: "2G" },
    { position: "5G" },
    { position: "6G" },
    { position: "NA" }
  ];
  export let inspectionTypes = [
    { inspection_type: "AUT" },
    { inspection_type: "X-Ray" },
    { inspection_type: "N/A" }
   
  ];
  
  export let materials = [
    { material: "X-60" },
    { material: "X-65" },
    { material: "X-70" },
    { material: "X-80" },
    { material: "X-100" },
    { material: "X-120" },
    { material: "13% CR" },
    { material: "Clad" },
    { material: "Various" },
    { material: "NA" },
    { material: "X-70/X-80" },
    { material: "X-65/X-70" },
    { material: "X-65/Clad" },
    { material: "X-52" },
    { material: "X-65/Various" },
    { material: "Other" },
    { material: "X-60/0,5% CR" },
    { material: "X-42" },
    { material: "SAWL415+UNS N08825" },
    { material: "UNS N06625" },
    { material: "X70/625Clad" },
    { material: "X65/316LClad" },
    { material: "SML 450" },
    { material: "SML 450/UNS NO6625" },
    { material: "22% Cr" },
    { material: "X65 + Inc 625" },
    { material: "SMLS 450FP" },
    { material: "SMLS 415P" },
    { material: "SMLS 360PD" },
    { material: "SMLS 450 PD" },
    { material: "13% Cr" },
    { material: "HFW 415 PD" },
    { material: "X60 + 316L" },
    { material: "HFW 315" },
    { material: "SAWL 415+316L" },
    { material: "SML 451" },
    { material: "CS X70" },
    { material: "Carbon" }
  ];
  