

//ZPAL-GLOBAL
export const FETCH_RAW_LIST = '@@fetch-raw-cashflow-List';
export const FETCH_RAW_LIST_SUCCESS = '@@fetch-raw-cashflow-List-success';
export const FETCH_RAW_LIST_FAILURE = '@@fetch-raw-cashflow-List-failure';


//ZPAL-GLOBAL
export const FETCH_TRANSFORM_LIST = '@@fetch-raw-transform-List';
export const FETCH_TRANSFORM_LIST_SUCCESS = '@@fetch-raw-transform-List-success';
export const FETCH_TRANSFORM_LIST_FAILURE = '@@fetch-raw-transform-List-failure';