/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, RightCircleTwoTone, FilePdfOutlined, DownCircleTwoTone, RightCircleOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography, Popover,
    Upload, notification, DatePicker, Drawer, Select, Layout, Row, Col, Card
} from 'antd';
// import { IonIcon } from "@ionic/react";
// import { closeOutline, cameraOutline, diamondOutline, chatbubbleOutline, alarmOutline, ellipsisHorizontal, ellipsisVertical, eyeOutline, createOutline, downloadOutline } from "ionicons/icons";
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';
import MasteForm from './MasteForm';
import MasteForm_Mappings from './Mappings';
import Supplement from './Supplement';
import MonthlyEndsResults from './MonthlyEndsResults';
import RawData from './RawDataPage';


const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const FinaceMapping = (props) => {

    const {

    } = props;


    const [isMappingVisible, setIsMappingVisible] = useState(false);
    const [isVisibleSupplemental, setIsVisibleSupplemental] = useState(false);
    const [isVisibleMonthlyEnd, setIsVisibleMonthlyEnd] = useState(false);
    const [isVisibleRawData, setIsVisibleRawData] = useState(false);

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);

    const [isRawData, setIsRawData] = useState(false);
    const showMasterModal = () => {
        setIsMappingVisible(true);
    }


    return (

        <div className="d-flex flex-column h-100">
            {!isMappingVisible && !isVisibleSupplemental && !isVisibleMonthlyEnd && !isVisibleRawData && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-2 align-items-center tab-content-title">
                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    history.push('/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>
                        <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Finance
                        </Title>
                        <div className="new-calibration">
                            {
                                // userGroup && userGroup.includes('pricing-admin') &&
                                <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                    onClick={() => setIsVisibleSupplemental(true)}
                                >
                                    Supplemental
                                </Button>
                            }
                            {
                                // userGroup && userGroup.includes('pricing-admin') &&
                                <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                    onClick={showMasterModal}
                                >
                                    Mappings
                                </Button>
                            }
                            {/* <Button shape="round" key="apply" type="primary"
                                onClick={showDrawer}
                                style={{ marginLeft: '10px' }}>
                                <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>
                                Filter
                            </Button> */}
                        </div>
                    </div>

                </div>
                <div className="custom-line" />
                {!isRawData &&
                    <div >
                        <Row gutter={24} className="detail-rows">
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        setIsVisibleMonthlyEnd(true)
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Monthly End Results"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        setIsVisibleRawData(true)
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Raw Data"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/project-margin');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Project Margin"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
                {/* {isRawData &&
                    <div >
                        <Row gutter={24} className="detail-rows">
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/project-margin');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Account Receivble"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/project-margin');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Consolidated P&L"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/project-margin');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Project Margin"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/project-margin');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Project WIP_Refurb Cost"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                } */}
            </div>
            }
            {isMappingVisible &&
                <MasteForm_Mappings
                    isMappingVisible={isMappingVisible}
                    setIsMappingVisible={setIsMappingVisible}
                />
            }
            {isVisibleSupplemental &&
                <Supplement
                    isVisibleSupplemental={isVisibleSupplemental}
                    setIsVisibleSupplemental={setIsVisibleSupplemental}
                />
            }
            {
                isVisibleMonthlyEnd &&
                <MonthlyEndsResults
                    isVisibleMonthlyEnd={isVisibleMonthlyEnd}
                    setIsVisibleMonthlyEnd={setIsVisibleMonthlyEnd}
                >

                </MonthlyEndsResults>
            }

            {isVisibleRawData &&
                <RawData
                    isVisible={isVisibleRawData}
                    setIsVisible={setIsVisibleRawData}
                >

                </RawData>

            }
        </div>
    );
};
const mapStateToProps = ({ }) => {
    return {
    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(FinaceMapping);