import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs } from 'antd';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { AddOrUpdateCashFlowProject, AddOrUpdateInputProject, DeleteDetails, generateEMEAASales, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import { countries_List } from '../TrackRecord/DatabseList.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ProjectDetailsGrid = (props) => {

    const { projectDetails, setProjectDetails } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [isRawListLoading, setisRawListLoading] = useState(false);

    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');
    const [activeKeyLine, setActiveKeyLine] = useState('INPUT')
    const [loading, setloading] = useState(false);
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    const [cashReceipts, setCashReceipts] = useState([])
    const [expenditureData, setExpenditureData] = useState([])
    const [formData, setFormData] = useState(projectDetails)
    const [newItem, setNewItem] = useState('');


    const tableNames = {
        KEY_ASSUMPTION: 'Key Assumption',
        INPUT: 'Input Projects',
        expenditure: 'Expenditure',
        cash_receipts: 'Cash Receipts',
        dashBoard: 'DashBoard'
    };

    useEffect(() => {
        console.log('rawDataList updated:', projectDetails);

        if (projectDetails)
          
            handleTabChange('KEY_ASSUMPTION');
    }, [])





    const reorderObjectExpenture = async (obj, key) => {
        // Static keys in the desired order
        let staticKeys = ["ID", "Section", "Category", "Type"];

        // Extract dynamic date-like keys using regex
        const dateKeys = Object.keys(obj).filter((key) => /\d{2}-[A-Za-z]{3}-\d{4}/.test(key));

        // Sort the date keys chronologically
        dateKeys.sort((a, b) => new Date(a) - new Date(b));

        // Extract `Revenue_*` and `Cost_*` keys dynamically
        const revenueAndCostKeys = Object.keys(obj).filter((key) => /^Revenue_\d+$|^Cost_\d+$/.test(key));

        // Sort `Revenue_*` and `Cost_*` keys by their numeric suffix
        revenueAndCostKeys.sort((a, b) => {
            const numA = parseInt(a.split("_")[1], 10);
            const numB = parseInt(b.split("_")[1], 10);
            return numA - numB;
        });

        // Determine slice value for static keys
        const sliceV = key === "cash_receipts" ? 6 : 3;

        // Combine keys: static keys, sorted date keys, and `Revenue_*`/`Cost_*` keys
        const orderedKeys = [
            ...staticKeys.slice(0, sliceV),
            ...dateKeys,
            ...staticKeys.slice(sliceV),
            ...revenueAndCostKeys, // Always place Revenue_* and Cost_* keys at the end
        ];

        // Create a new object with the desired key order
        const reorderedObj = {};
        orderedKeys.forEach((key) => {
            if (key in obj) {
                reorderedObj[key] = obj[key];
            }
        });

        return reorderedObj;
    };


    const generateInputColumns = async (columnList, tapName) => {

        const columnDefs = [
            { field: 'group', headerName: 'Group', rowGroup: true, hide: true }, // For grouping
            { field: 'field', headerName: 'Field Name', editable: false }, // Field name
            {
                field: 'value',
                headerName: 'Value',
                editable: true, // Enable editing
                cellEditor: params => {
                    if (params.data.field.includes('date')) {
                        return 'agDateCellEditor'; // Use AG Grid's date editor
                    }
                    if (!isNaN(params.value)) {
                        return 'agTextCellEditor'; // Use numeric editor
                    }
                    return 'agLargeTextCellEditor'; // Default editor for strings
                },
                valueFormatter: params => {
                    if (!isNaN(params.value)) {
                        return Number(params.value).toLocaleString(); // Format numbers
                    }
                    return params.value;
                },
            },
        ];


        // Debugging output for generated columns
        //console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };

    const transformObjectToRowData =async (obj) => {
        const groupedData = {};

        // Define categories (or derive dynamically)
        const categories = {
            GeneralInfo: ['Name', 'Country'],
            Dates: [
                'Expected_signing_date',
                'Start_of_job',
                'Leaves_port',
                'Arrives_port',
                'Completion_of_PQT',
                'Production_start',
                'Production_end',
            ],
            PaymentTerms: [
                'Payment_terms',
                'PG_required_of_contract',
                'Commission',
                'WHT',
                'AP',
                'Advance_payment_terms',
                'Arrival_at_port_payment_terms',
                'Completion_of_PQT_payment_terms',
            ],
            Technicians: [
                'Senior_technicians',
                'Lead_technicians',
                'Tech_rate',
                'Senior_technicians_rev',
                'Lead_technicians_rev',
            ],
            Pipeline: [
                'Pipelines_count',
                'welding_wire_order',
                'Welding_wire_shipment',
            ],
        };

        // Group the object properties based on categories
        Object.entries(categories).forEach(([category, keys]) => {
            groupedData[category] = keys
                .filter(key => key in obj)
                .map(key => ({ field: key, value: obj[key] }));
        });

        // Flatten grouped data into AG Grid rowData format
        const rowData = Object.entries(groupedData).map(([group, children]) => ({
            group,
            children,
        }));

        return rowData;
    };


    const fetchData = async (data, key) => {

        const rowData = await transformObjectToRowData(data)
        console.log('transfromData' ,rowData)
        setRowData(rowData);
    };

    const convertToAssumptionArray = (inputObj) => {
        // Map each key-value pair into the desired object format, excluding certain keys
        return Object.entries(inputObj)
            .filter(([key]) => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'InputProjectData' && key !== 'ID') // Filter out unwanted keys
            .map(([key, value]) => ({
                Assumption_Key: key.replace(/_/g, ' '), // Replace underscores with spaces
                Value: value
            }));
    };


    const handleTabChange = async (key) => {

        console.log(key)
        setloading(true)


        setActiveKeyLine(key);
        //setRowData([])
        //    if (key === 'INPUT')
        {

            setloadingData(true);
           // setColumnDefs(null);
           // setRowData(null)
           await generateInputColumns();
            await fetchData(projectDetails, key)
            setloadingData(false);
        }


        setSelectedMatrialClass(key);
        setloading(false)
    }
        ;

    // useEffect(() => {
    //     console.log('rawDataList updated:', rawDataList.length);

    //     if (rawDataList[activeKeyLine])
    //         fetchData();
    // }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
            filter: true,
        };
    }, []);


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log("params", params.api)
        setGridApi(params.api)
        console.log("params", params.api)
        if (loadingData) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [loadingData, activeKeyLine]);



    // useEffect(() => {
    //     //console.log(activeKeyLine)
    //     if (gridRef.current) {
    //         console.log(activeKeyLine)
    //         if (loadingData) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [loadingData, activeKeyLine])

    //

    useEffect(() => {
        if (loadingData) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (loadingData && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);


    const OnGenerateCashFlow2 = async () => {
        setIsGenerateLoading(true)
       // await OnGenerateCashFlow()
        setIsGenerateLoading(false)
    }

    // Custom status panel component
    const CustomStatusPanel = () => {
        return (
            <>

                <div style={{ paddingTop: '10px' }}>
                   

                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '0px', marginBottom: '-20px' }}
                        loading={isGenerateLoading}
                       // onClick={async () => await OnGenerateCashFlow2()}
                    >
                        Generate Cash Flow
                    </Button>

                </div>

            </>
        )

    };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: () => <CustomStatusPanel />, // React component
                align: 'right',
            },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);




    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };




    function onCellValueChanged(event) {
        // console.log(
        //     "onCellValueChanged: " + event.colDef.field + " = " + event.newValue,
        // );
        // console.log(
        //     event
        // );

        if (event.data) {
            // AddOrUpdateInputProject(event.data)
        }
    }

;
    // }

 


    return (

        <div>


            <div style={{ height: 'calc(100vh - 185px)', overflow: 'auto', position: 'relative' }}>
                <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowDragManaged={true}
                        onRowDragEnd={onRowDragEnd}
                        defaultColDef={defaultColDef}
                        rowSelection={rowSelection}
                        onGridReady={onGridReady}
                        sideBar={false}
                        hiddenByDefault={true}
                        pivotMode={false}
                        groupDefaultExpanded= {1} // Expand all groups initially
                        //
                        pivotPanelShow={"never"}
                        autoGroupColumnDef={autoGroupColumnDef}
                        animateRows={true}
                        onSelectionChanged={onSelectionChanged}
                        // enableFilter={true}
                        statusBar={statusBar}
                        rowHeight={40}
                        headerHeight={50}
                        //defaultExcelExportParams={defaultExcelExportParams}
                        //defaultCsvExportParams={defaultCsvExportParams}
                        // loadingOverlayComponent={CustomLoadingCellRenderer}
                        // loadingOverlayComponentParams={{
                        //     loadingMessage: "Loading all rows...",
                        // }}
                        onCellValueChanged={onCellValueChanged}
                    //onRowValueChanged={onRowValueChanged}

                    />

                </div>
            </div>

          

        </div>

    );


};
const mapStateToProps = ({ }) => {

    return {

    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsGrid);