// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space, Typography } from 'antd';


import moment from 'moment';
import { EditFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { countries_List } from '../TrackRecord/DatabseList';
import { AddOrUpdateCashFlowProject, generateEMEAASales, getListFromDatabase } from '../../redux/EMEAA/saga';
//import { Contractor_List, countries_List, diameters, PipeDiaRate, Project_List, Representative_List, Spread_Config_List, Pipe_Grade_List } from './Database';
const { Option } = Select;

const ProjectModal = (props) => {

    const {

        isModalVisible,
        onClose,
        setIsModalVisible,
        projectDetailForm,
        isEdit,
        setIsEdit,
        editRecord,
        setShowPipelineModel,
        setProjectDetails

    } = props;
    const [newItem, setNewItem] = useState('');
    const [inputData, setInputData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({

        "Name": "Project 1",
        "Country": "USA",
        "Expected_signing_date": "2024-12-01",
        "Start_of_job": "2025-04-01",
        "Leaves_port": "2025-03-15",
        "Arrives_port": "2025-03-18",
        "Completion_of_PQT": "2025-03-25",
        "Production_start": "2025-04-01",
        "Production_end": "2025-08-01",
        "Payment_terms": "30",
        "PG_required_of_contract": 10,
        "Commission": 5,
        "WHT": 10,
        "AP": 30,
        "Pipelines_count": 1,
        "welding_wire_order": "2024-09-30",
        "Welding_wire_shipment": "2024-11-29",
        "Senior_technicians": 550, // hours
        "Lead_technicians": 450, // house
        "Tech_rate": 150,
        "Advance_payment_terms": 0.3,
        "Senior_technicians_rev": 149832,
        "Lead_technicians_rev": 141947,
        "Arrival_at_port_payment_terms": 0.2,
        "Completion_of_PQT_payment_terms": 0.25

    });

    useEffect(() => {
        if (isEdit) {
            setFormData(editRecord)
            console.log(editRecord)
        }

        (async () => {

            if (inputData.length == 0) {
                const res = await getListFromDatabase('Project_Inputs')
                if (res) {
                    console.log('Input Data', res)
                    setInputData(res)
                }
            }

            // setloading(false)
        })();
    }, []);


    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
    };


    const addItem = async (type, newItem) => {
        // console.log(type, newItem, categoryListdata)
        if (newItem) {

            // const newItem1 = { [tableNames[type]]: newItem }; // Example item
            // addItemToCategoryList(type, newItem1);
            // const obj = {
            //     tableName: type,
            //     data: [{
            //         [tableNames[type]]: newItem
            //     }]
            // };
            setNewItem('')
            // console.log(obj);
            // Uncomment and implement this function
            // await AddOrUpdateMasterData(obj)

            // const category = await getCategoryListFromDatabse();
            // if (category)
            //     setCategoryListdata(category)
            // console.log(category)
        }


    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    const handleInputChange = (name, value) => {

        if (name === 'Expected_signing_date' || name == 'Start_of_job' || name === 'Leaves_port' || name === 'Arrives_port' ||
            name === 'Production_start' || name === 'Production_end' || name === 'welding_wire_order' || name === 'Completion_of_PQT' || name === 'Welding_wire_shipment')
            value = moment(value).format('YYYY-MM-DD');

        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };

    const handleCancel = () => {
        projectDetailForm.resetFields();
        setIsEdit(false)

        setFormData({})
        //onClose();
        setIsModalVisible(false)

    };


    const getCategoryValue1 = (type) => {
        if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
            return formData[type]; // Return the value for the category
        }
    };

    const handleOk = async () => {
        setIsLoading(true)
        // let inputDataValue;
        // if (formData['Pipelines_count'] > 1) {
        //     const count = formData['Pipelines_count']; // Number of pipelines
        //     const dataInput = inputData.map(item => ({ ...item })); // Create a shallow copy of each object in the array
        //     for (let index = 1; index < count; index++) { // Start from 1 to avoid duplicating existing tags
        //         dataInput.forEach((item) => {
        //             // Copy values for Revenue and Cost with the updated index
        //             item[`Revenue_${index + 1}`] = item['Revenue_1'];
        //             item[`Cost_${index + 1}`] = item['Cost_1'];
        //         });
        //     }
        //     console.log(dataInput);
        //     inputDataValue = dataInput
        // }
        // else {
        //     inputDataValue = inputData
        // }


        // const data = {
        //     'Project_details': formData,
        //     Inputs: inputData
        // }
        // console.log(data)

        // const res = await generateEMEAASales(data)
        // console.log(res)
        if (!isEdit)
            formData['Created_Date'] = moment().format('YYYY-MM-DD').toString()
        formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()
        // if (res.expenditure)
        //     setExpenditureData(res.expenditure)
        // if (res.cash_receipts)
        //     setCashReceipts(res.cash_receipts)
        //formData['CashFlowData'] = res
        const res = await AddOrUpdateCashFlowProject(formData)
        if (res && res.length > 0) {
            console.log(res)
            setProjectDetails(res[0])
            setShowPipelineModel(true)
        }
        setIsLoading(true)
        setIsEdit(false)
        setIsModalVisible(false)
    }

    return (
        <Modal
            width={1200}
            visible={isModalVisible}
            title="New Project"
            className="pipeline-modal trackrecord-modal Tracker-modal"
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary"
                    onClick={async () => await handleOk()}
                    loading={isLoading}
                >
                    {isEdit ? 'Update' : 'Submit'}
                </Button>,
            ]}
        >
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                //onValuesChange={onValuesChange}
                width={1200}
            >
                <Row gutter={16} >

                    <Col lg={5}>
                        <Form.Item name="Name" label="Project Name" required>
                            <div>
                                <Input onChange={(e) => handleInputChange('Name', e.target.value)} size="large"
                                    value={getCategoryValue1('Name')} />
                            </div>
                        </Form.Item>
                    </Col>



                    <Col lg={5}>
                        <Form.Item name="Country" label="Country">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Country', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Countries_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {countries_List &&
                                    countries_List
                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                {item.country}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Expected_signing_date" label="Expected Signing Date" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Expected_signing_date'])}
                                    onChange={(date) => handleInputChange('Expected_signing_date', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Start_of_job" label="Start of Job" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Start_of_job'])}
                                    onChange={(date) => handleInputChange('Start_of_job', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Completion_of_PQT" label="Completion of PQT" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Completion_of_PQT'])}
                                    onChange={(date) => handleInputChange('Completion_of_PQT', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>



                    <Col lg={5}>
                        <Form.Item name="Arrives_port" label="Arrives Port" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Arrives_port'])}
                                    onChange={(date) => handleInputChange('Arrives_port', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>

                        </Form.Item>
                    </Col>



                    <Col lg={5}>
                        <Form.Item name="Leaves_port" label="Leaves Port" required>
                            <div>
                                <DatePicker
                                    value={moment(formData['Leaves_port'])}
                                    onChange={(date) => handleInputChange('Leaves_port', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Production_start" label="Production Start" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Production_start'])}
                                    onChange={(date) => handleInputChange('Production_start', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Production_end" label="Production End" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Production_end'])}
                                    onChange={(date) => handleInputChange('Production_end', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="welding_wire_order" label="welding Wire Order" >
                            <div>
                                <DatePicker
                                    value={moment(formData['welding_wire_order'])}
                                    onChange={(date) => handleInputChange('welding_wire_order', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Welding_wire_shipment" label="Welding Wire Shipment" >
                            <div>
                                <DatePicker
                                    value={moment(formData['Welding_wire_shipment'])}
                                    onChange={(date) => handleInputChange('Welding_wire_shipment', date)}
                                    format="DD-MMM-YYYY"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Payment Terms" name="Payment_terms">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='days' className='Data360-addafter'
                                    onChange={(e) => handleInputChange('Payment_terms', Number(e.target.value))}
                                    value={getCategoryValue1('Payment_terms')}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    min={0}
                                    type="number"
                                    size="large"
                                />

                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Senior Technicians" name="Senior_technicians">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Input addonAfter='hr' className='Data360-addafter'
                                    onChange={(e) => handleInputChange('Senior_technicians', Number(e.target.value))}
                                    value={getCategoryValue1('Senior_technicians')}
                                    min={0}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Lead Technicians" name="Lead_technicians">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Input addonAfter='hr' className='Data360-addafter'
                                    onChange={(e) => handleInputChange('Lead_technicians', Number(e.target.value))}
                                    value={getCategoryValue1('Lead_technicians')}
                                    min={0}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Pipelines Count" name="Pipelines_count">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input onChange={(e) => handleInputChange('Pipelines_count', Number(e.target.value))}
                                    value={getCategoryValue1('Pipelines_count')}
                                    style={{ width: '100%', textAlign: 'left' }}
                                    min={0}
                                    type="number"
                                    size="large"
                                />

                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="PG Required of Contract" name="PG_required_of_contract">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('PG_required_of_contract')}
                                    onChange={(e) => handleInputChange('PG_required_of_contract', Number(e.target.value))}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Commission" name="Commission">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('Commission')}
                                    onChange={(e) => handleInputChange('Commission', Number(e.target.value))}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="WHT" name="WHT">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('WHT')}
                                    onChange={(e) => handleInputChange('WHT', Number(e.target.value))}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>


                    <Col lg={5}>
                        <Form.Item label="AP" name="AP">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('AP')}
                                    onChange={(e) => handleInputChange('AP', Number(e.target.value))}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item label="Advance Payment Terms" name="Advance_payment_terms">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('Advance_payment_terms') * 100}
                                    onChange={(e) => handleInputChange('Advance_payment_terms', Number(e.target.value) / 100)}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="Arrival at Port Payment Terms" name="Arrival_at_port_payment_terms">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('Arrival_at_port_payment_terms') * 100}
                                    onChange={(e) => handleInputChange('Arrival_at_port_payment_terms', Number(e.target.value) / 100)}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="Completion PQT Payment Terms" name="Completion_of_PQT_payment_terms">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input addonAfter='%' className='Data360-addafter'
                                    value={getCategoryValue1('Completion_of_PQT_payment_terms') * 100}
                                    onChange={(e) => handleInputChange('Completion_of_PQT_payment_terms', Number(e.target.value) / 100)}
                                    min={1}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="Tech Rate" name="Tech_rate">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Input addonBefore='$' className='Data360-addbefore'
                                    onChange={(e) => handleInputChange('Tech_rate', Number(e.target.value))}
                                    value={getCategoryValue1('Tech_rate')}
                                    min={0}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />
                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item label="Senior Technicians Rev" name="Senior_technicians_rev">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Input addonBefore='$' className='Data360-addbefore'
                                    onChange={(e) => handleInputChange('Senior_technicians_rev', Number(e.target.value))}
                                    value={getCategoryValue1('Senior_technicians_rev')}
                                    min={0}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />
                            </div>
                        </Form.Item>
                    </Col>


                    <Col lg={5}>
                        <Form.Item name="Lead_technicians_rev" label="Lead Technicians Rev">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Input addonBefore='$' className='Data360-addbefore'
                                    onChange={(e) => handleInputChange('Lead_technicians_rev', Number(e.target.value))}
                                    value={getCategoryValue1('Lead_technicians_rev')}
                                    min={0}
                                    type='number'
                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                />
                            </div>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    );
};

export default ProjectModal;
