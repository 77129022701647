import get from 'lodash/get';
import { FETCH_RAW_LIST, FETCH_RAW_LIST_FAILURE, FETCH_RAW_LIST_SUCCESS } from './types';

const initialState = {
  isFetchLoading: false,
  rawDataList: {}, // Store data dynamically by tableName
  tableName: '', // Tracks the currently active table
};

const FinanceMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RAW_LIST: {
      const tableName = get(action, 'tableName', 'default');
      return {
        ...state,
        tableName, // Update the tableName in the state
        rawDataList: {
          ...state.rawDataList,
          [tableName]: [], // Reset the data for the specific table
        },
        isFetchLoading: true,
      };
    }

    case FETCH_RAW_LIST_SUCCESS: {
      const resData = get(action, 'response', []);
      const tableName = state.tableName; // Use the current tableName from state
      return {
        ...state,
        rawDataList: {
          ...state.rawDataList,
          [tableName]: resData, // Update data for the specific table
        },
        isFetchLoading: false,
      };
    }

    case FETCH_RAW_LIST_FAILURE: {
      return {
        ...state,
        isFetchLoading: false, // Simply update the loading state
      };
    }

    default: {
      return state;
    }
  }
};

export default FinanceMappingReducer;
