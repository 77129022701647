/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import CrcPmTable from './CrcPmTable';
import CmCrcTable from './CmCrcTable';
import RawSummery from './RawSummery';
import { GetRefreshInfo } from '../../../redux/FinanceMapping/saga';

const { TabPane } = Tabs;
const { Title,Text } = Typography;

const RawCards = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('CRC_PM_FBL3N');
    const [refreshInfo, setRefreshInfo] = useState(null);
    // useEffect(() => {
      
    //     fetchInfo('CRC_PM_FBL3N');
    // }, []);

    // const fetchInfo = async (key) => {
    //     try {
    //         const info = await GetRefreshInfo(key);
    //         if (info.Date_Time){
    //             setRefreshInfo(info.Date_Time)
    //         }
    //     } catch (error) {
    //         console.error("Error fetching info:", error);
    //     }
    // };


    const onChange = async(key) => {
        setRefreshInfo('')
        setCurrentSection(key);
       //await fetchInfo(key)
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex mb-2 align-items-center tab-content-title">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={(e) => {
                            history.push('/raw-data');
                        }} />
                    </Tooltip>
                </div>
                <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    Project Margin
                </Title>

                {/* <div className="new-calibration">
                <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Refresh Date: {refreshInfo}</Text>
                </div> */}
            </div>
            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="CRC PM FBL3N" key="CRC_PM_FBL3N">
                        <CrcPmTable />
                    </TabPane>
                    <TabPane tab="CM CRC PM FBL3N" key="CM_CRC_PM_FBL3N">
                        <CmCrcTable />
                    </TabPane>
                    <TabPane tab="CRC PM FBL3N_CONSOL" key="CRC_PM_FBL3N_CONSOL">
                        <RawSummery />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default RawCards