import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import { fetchRawCrcPmListSuccess,fetchRawCrcPmListFailure, fetchRawCmCrcListSuccess, fetchRawCmCrcListFailure, fetchRawProjectSummeryListSuccess, fetchRawProjectSummeryListFailure, fetchZPALGLOBALListFailure, fetchZPALGLOBALListSuccess } from './action';
import { FETCH_RAW_CRC_PM_LIST, FETCH_RAW_CM_CRC_LIST, FETCH_RAW_PROJECT_SUMMERY_LIST, FETCH_RAW_ZPALGLOBAL_LIST } from './types';
import axiosConfig from './axiosConfig';


const fetchDataPage = (tableName, pageNum, pageSize) => {
  return axiosConfig.get(`/GeProcesstList?Table_Name=${tableName}&pageNumber=${pageNum}&pageSize=${pageSize}`);
};



/** Get all raw crc pm list */
function* fetchRawCrcPmList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    const queryParams = [];

    const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(({ colId, sort }) => {
        const sortDirection = typeof sort === 'string' ? sort.toUpperCase() : '';
        queryParams.push(`${colId}=${sortDirection}`);
      });
    }

    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield call(axiosConfig.get, url);

    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage,tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }

      yield put(fetchRawCrcPmListSuccess({ response: primaryData }));
    } else {
      notification.error({ message: 'Something went wrong.' });
    }
  } catch (error) {
    yield put(fetchRawCrcPmListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: error.toString(),
    });
  }
}

/** Get all raw crc pm list */
function* fetchRawCmCrcList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(item => {
          const sortDirection = typeof item.sort === 'string' ? item.sort.toUpperCase() : '';
          queryParams.push(`${item.colId}=${sortDirection}`);
      });
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    
    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage,tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }
      yield put(fetchRawCmCrcListSuccess({ response: primaryData }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRawCmCrcListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: `${error}`,
    });
  }
}

/** Get all raw crc pm list */
function* fetchRawProjectSummeryList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(item => {
          const sortDirection = typeof item.sort === 'string' ? item.sort.toUpperCase() : '';
          queryParams.push(`${item.colId}=${sortDirection}`);
      });
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    
    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage,tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }
      yield put(fetchRawProjectSummeryListSuccess({ response: primaryData }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRawProjectSummeryListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: `${error}`,
    });
  }
}
/** Get all raw crc pm list */
function* fetchZPAL_GLOBAList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(item => {
          const sortDirection = typeof item.sort === 'string' ? item.sort.toUpperCase() : '';
          queryParams.push(`${item.colId}=${sortDirection}`);
      });
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    
    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage,tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }
      yield put(fetchZPALGLOBALListSuccess({ response: primaryData }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchZPALGLOBALListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: `${error}`,
    });
  }
}

function* projectMarginWatcher() {
  yield takeLatest(FETCH_RAW_CRC_PM_LIST, fetchRawCrcPmList);
  yield takeLatest(FETCH_RAW_CM_CRC_LIST, fetchRawCmCrcList);
  yield takeLatest(FETCH_RAW_PROJECT_SUMMERY_LIST, fetchRawProjectSummeryList);
  //yield takeLatest(FETCH_RAW_ZPALGLOBAL_LIST, fetchZPAL_GLOBAList);
}

function* projectMarginSagas() {
  yield all([
    call(projectMarginWatcher),
  ]);
}

export default projectMarginSagas;