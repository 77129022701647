import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import axiosConfig from './axiosConfig';
import { fetchCashflowListFailure, fetchCashflowListSuccess } from './action';
import { FETCH_RAW_LIST } from './types';

const fetchDataPage = (tableName, pageNum, pageSize) => {
  return axiosConfig.get(`/GeProcesstList?Table_Name=${tableName}&pageNumber=${pageNum}&pageSize=${pageSize}`);
};


/** Get all raw crc pm list */
function* fetchList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(item => {
        const sortDirection = typeof item.sort === 'string' ? item.sort.toUpperCase() : '';
        queryParams.push(`${item.colId}=${sortDirection}`);
      });
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);

    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage, tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }
      yield put(fetchCashflowListSuccess({ response: primaryData }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchCashflowListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw datalist.',
      description: `${error}`,
    });
  }
}



function* emeaaWatcher() {

  yield takeLatest(FETCH_RAW_LIST, fetchList);
}

function* emeaaSagas() {
  yield all([
    call(emeaaWatcher),
  ]);
}

export default emeaaSagas;




export const generateEMEAASales = async (data) => {
  try {

    const url = 'https://bbk2gmd2a2.execute-api.us-west-2.amazonaws.com/dev/generate_cash_flow'

    //const url = 'http://localhost:5000/generate_cash_flow'
    console.log('Url Data::', data)



    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();

    if (res.status === 200) {
      const rawResponse = await res.text();
      // Parse the raw text into a JSON object
      //console.log('rawResponse', rawResponse)
      let response1;
      try {
        response1 = JSON.parse(rawResponse);
      } catch (jsonError) {
        console.error("Error Parsing JSON:", jsonError);
        return null;
      }


      console.log(response1.EMEAA_details)

      return response1.EMEAA_details
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    console.log('error', error)
    return null;
  }
};


export const AddOrUpdateCashFlowProject = async (data, isEdit = false, isSendMaill = false) => {

  try {

    if (data) {

      const list = {
        'tableName': "Project_Details",
        data:[data],
        isEdit: isEdit
      }
      const res = await axiosConfig.post('/AddOrUpdate', list);

      notification.destroy();
      // console.log(yield res.json)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        notification.success({
          message: data.isEdit || isEdit ? 'Updated Successfully.' : 'Added Successfully.',
          duration: 3,
        });

        return responseData

      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return null

      }
    }

  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}


export const AddOrUpdateInputProject = async (data, isEdit = true, isSendMaill = false) => {


  try {

    if (data) {

      const list = {
        'tableName': "Project_Inputs",
        data:[data],
        isEdit: isEdit
      }
      const res = await axiosConfig.post('/AddOrUpdate', list);

      notification.destroy();
      // console.log(yield res.json)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        notification.success({
          message: data.isEdit || isEdit ? 'Update Successfully.' : 'Added Successfully.',
          duration: 3,
        });

        return responseData

      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return null

      }
    }

  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const DeleteDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/Delete', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}



export const getListFromDatabase = async (tableName) => {
  try {
    let url;
    url = `/GetList?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};