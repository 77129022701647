import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin, notification, Typography, Tooltip, Tabs, Row, Col } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { CustomLoadingCellRenderer, restoreGridLayout, saveGridLayout } from './Uitiles.js';
import { fetchRawList } from '../../redux/FinanceMapping/action.js';
import { GetRefreshInfo } from '../../redux/FinanceMapping/saga.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const Supplement = (props) => {

    const { onFetchRawList, rawDataList, isRawListLoading, isVisibleSupplemental, setIsVisibleSupplemental } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);

    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');
    const [activeKeyLine, setActiveKeyLine] = useState('CN43N')
    const [loading, setloading] = useState(false);
    const [TableDisplayList, setTableDisplayList] = useState([])
    const [TableIndexList, setTableIndexList] = useState([])
    const [AddUpdateList, setAddUpdateList] = useState({})
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    // useEffect(() => {

    //     fetchInfo('ZPAL_GLOBAL');
    // }, []);

    const fetchInfo = async (key) => {
        try {
            const info = await GetRefreshInfo(key);
            console.log('Refresh Time', info)
            if (info.Date_Time) {
                setRefreshInfo(info.Date_Time)
            }
        } catch (error) {
            console.error("Error fetching info:", error);
        }
    };
    const tableNames = {
        CN43N: 'CN43N',
        CN43N_Proj_PC: 'CN43N Proj PC',
        COOIS_MASTER_Prod_Orders: 'COOIS Master Prod Orders',
        CUSTOMERS: 'Customers',
        INTERNAL_ORDERS_KOB1: 'Internal Orders KOB1',
        PROJECT_NAMES: 'Project Names',
        REFURB_ORDERS_IW39: 'Refurb Orders IW39',
        SOG_TRADE_DATA: 'SOG Trade Data',
        VENDOR_MASTER: 'Vendor Master'
    };

    useEffect(() => {
        (async () => {
            if (data_List.length == 0) {
                setloading(true)
                const initialClass = Object.keys(tableNames)[0];
                handleTabChange(initialClass)
            }
            // setloading(false)
        })();
    }, []);

    const generateColumns = async (columnList) => {
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'ID' && key !== 'totalCount') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly

                // valueFormatter: (params) => {
                //     // Check if the column is a date column
                //     const isDateColumn = ['PlanRfrom', 'PlanR_to', 'Capdate', 'EquipCrDt', 'RetDate', 'StrtDate'].includes(key);

                //     if (isDateColumn) {
                //         // Validate and format the date
                //         const date = moment(params.value); // Parse date with moment.js
                //         return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                //     }


                //     // Return value for non-date columns
                //     return params.value;
                // },


                cellStyle: { textAlign: 'left' }, // Cell alignment
                editable: true, // Enable editing for cells
                filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));

        // Debugging output for generated columns
        console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };


    const fetchData = async (data) => {
        if (data.length > 0) {

            generateColumns(data[0]);
            fetchInfo(activeKeyLine);
            setRowData(data);
            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };


    const handleTabChange = async (key) => {

        // console.log(key)
        setloading(true)
        //setColumnDefs([]);
        //setRowData([])

         //setRowData([])
         if (!rawDataList[key] || rawDataList[key].length === 0)
         {
            setColumnDefs(null);
            setRowData(null)
            onFetchRawList({ tableName: key, pageNumber: pageNumber, pageSize: pageSize });
         }
        else {
            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)
            await fetchData(rawDataList[key])
            setloadingData(false);
        }
        setActiveKeyLine(key);

        setSelectedMatrialClass(key);
        setloading(false)
    }


    useEffect(() => {
        console.log('rawDataList updated:', rawDataList.length);
    
        if (rawDataList[activeKeyLine])
            fetchData(rawDataList[activeKeyLine]);
    }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        };
    }, []);


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawListLoading]);


    useEffect(() => {
        if (gridRef.current)
        {
            if (loadingData) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loadingData])

    //

    useEffect(() => {
        if (isRawListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (isRawListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);

    // Custom status panel component
    const CustomStatusPanel = ({ refreshInfo }) => {
        return <div style={{ paddingTop: '20px' }}>{refreshInfo}</div>;
    };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: () => <CustomStatusPanel refreshInfo={refreshInfo} />, // React component
                align: 'right',
            },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };




    const getPipelineTabpanes = () => {


        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>
                <Tabs activeKey={selectedMatrialClass} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames).map((row) => (
                        <TabPane tab={tableNames[row]} key={row}>
                            {
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <div style={{ height: 'calc(100vh - 160px)', overflow: 'auto', position: 'relative' }}>
                                            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={columnDefs}
                                                    rowDragManaged={true}
                                                    onRowDragEnd={onRowDragEnd}
                                                    defaultColDef={defaultColDef}
                                                    rowSelection={rowSelection}
                                                    onGridReady={onGridReady}
                                                    sideBar={true}
                                                    pivotMode={isPivotMode}
                                                    //
                                                    pivotPanelShow={"always"}
                                                    autoGroupColumnDef={autoGroupColumnDef}
                                                    animateRows={true}
                                                    onSelectionChanged={onSelectionChanged}
                                                    // enableFilter={true}
                                                    statusBar={statusBar}
                                                    rowHeight={40}
                                                    headerHeight={50}
                                                    defaultExcelExportParams={defaultExcelExportParams}
                                                    defaultCsvExportParams={defaultCsvExportParams}
                                                    loadingOverlayComponent= {CustomLoadingCellRenderer}
                                                    loadingOverlayComponentParams={{
                                                        loadingMessage: "Loading all rows...",
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        );
    };

    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setIsVisibleSupplemental(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    Supplement
                </Title>

                <div className="new-calibration" style={{ display: 'flex', alignItems: 'center' }}>

                    {/* <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ marginRight: '5px' }}>
                            <svg style={{ width: 'auto', height: 'auto' }}>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>
                    <Button shape="round" key="apply" type="primary" onClick={() => createExcelTemplate()} style={{ marginLeft: '20px' }} loading={downloadLoading}>
                        Download As Excel
                    </Button>

                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                        onClick={showBulkUploadModal}
                    >
                        <CloudUploadOutlined style={{ fontSize: '20px' }}> </CloudUploadOutlined>
                        Bulk Upload
                    </Button> */}
                </div>

            </div>

            {getPipelineTabpanes()}


        </div>
    );


};

const mapStateToProps = ({ FinanceMappingReducer }) => {
    const isRawListLoading = get(FinanceMappingReducer, 'isFetchLoading', false);
    const rawDataList = get(FinanceMappingReducer, `rawDataList`, {}); // Fetch specific table data

    return {
        isRawListLoading,
        rawDataList,
    }
}

const mapDispatchToProps = {
    onFetchRawList: fetchRawList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplement);