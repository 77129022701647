import { FETCH_RAW_ZPALGLOBAL_LIST, FETCH_RAW_ZPALGLOBALLIST_SUCCESS, FETCH_RAW_ZPALGLOBAL_FAILURE, FETCH_TRANSFORM_LIST, FETCH_TRANSFORM_LIST_SUCCESS, FETCH_TRANSFORM_LIST_FAILURE } from './types';




// RAW CM CRC list
const fetchZPALGLOBALList = (payload) => ({
  type: FETCH_RAW_ZPALGLOBAL_LIST,
  ...payload
});

const fetchZPALGLOBALListSuccess = (payload) => ({
  type: FETCH_RAW_ZPALGLOBALLIST_SUCCESS,
  ...payload
});

const fetchZPALGLOBALListFailure = () => ({
  type: FETCH_RAW_ZPALGLOBAL_FAILURE
});



// RAW CM CRC list
const fetchTransformList = (payload) => ({
  type: FETCH_TRANSFORM_LIST,
  ...payload
});

const fetchTransformListSuccess = (payload) => ({
  type: FETCH_TRANSFORM_LIST_SUCCESS,
  ...payload
});

const fetchTransformListFailure = () => ({
  type: FETCH_TRANSFORM_LIST_FAILURE
});


export {

  fetchZPALGLOBALList,fetchZPALGLOBALListSuccess,fetchZPALGLOBALListFailure,
  fetchTransformList,fetchTransformListSuccess,fetchTransformListFailure
};