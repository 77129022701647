import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Pagination, Spin, Typography, Tooltip, Tabs } from 'antd';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space } from 'antd';
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined, FullscreenOutlined, ShrinkOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import history from '../../services/history.js';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import moment from 'moment';
import { AddOrUpdateCashFlowProject, AddOrUpdateInputProject, DeleteDetails, generateEMEAASales, getListFromDatabase } from '../../redux/EMEAA/saga.js';
import { countries_List } from '../TrackRecord/DatabseList.js';
import { AgCharts } from 'ag-charts-react';
import ProjectsInputs from './ProjectsInputs.js';
import { CustomLoadingCellRenderer } from './Uitiles.js';
import ProjectDetailsAgGrid from './ProjectDetailsAgGrid.js';


ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ProjectsTaps = (props) => {

    const { rawDataList, isRawListLoading, setShowPipelineModel, showPipelineModel, projectDetails, setProjectDetails, projectDetailForm } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [currentSection, setCurrentSection] = useState('1');
    const [rowData, setRowData] = useState(null);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);


    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');
    const [activeKeyLine, setActiveKeyLine] = useState('')
    const [loading, setloading] = useState(false);
    const [data_List, setData_List] = useState([]);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const [loadingData, setloadingData] = useState(false);
    const [cashReceipts, setCashReceipts] = useState([])
    const [expenditureData, setExpenditureData] = useState([])
    const [formData, setFormData] = useState(projectDetails)
    const [newItem, setNewItem] = useState('');
    const [inputData, setInputData] = useState([]);
    const [dashBoardCashReceipts, setDashBoardCashReceipts] = useState({});
    const [dashBoardExpenditureData, setDashBoardExpenditureData] = useState({});

    const tableNames = {
        KEY_ASSUMPTION: 'Key Assumption',
        INPUT: 'Input Projects',
        expenditure: 'Expenditure',
        cash_receipts: 'Cash Receipts',
        dashBoard: 'DashBoard'
    };

    useEffect(() => {
        (async () => {

            if (inputData.length == 0) {
                // const data = {
                //     'Project_details': projectDetails,
                //     Inputs: inputData.Inputs
                // }
                // console.log(data)

                // const res = await generateEMEAASales(data)
                // console.log(res)
                // projectDetails['CashFlowData'] = res
                // setProjectDetails()
                if (projectDetails['CashFlowData']) {
                    if (projectDetails['CashFlowData'].expenditure)
                        setExpenditureData(projectDetails['CashFlowData'].expenditure)
                    if (projectDetails['CashFlowData'].cash_receipts)
                        setCashReceipts(projectDetails['CashFlowData'].cash_receipts)
                }

                if (projectDetails['InputProjectData'] && projectDetails['InputProjectData'].length > 0)
                    setInputData(projectDetails['InputProjectData'])

                else if (inputData.length == 0) {
                    const res = await getListFromDatabase('Project_Inputs')
                    if (res) {
                        console.log('Input Data', res)
                        let inputDataValue;
                        if (formData['Pipelines_count'] > 1) {
                            const count = formData['Pipelines_count']; // Number of pipelines
                            const dataInput = res.map(item => ({ ...item })); // Create a shallow copy of each object in the array
                            for (let index = 1; index < count; index++) { // Start from 1 to avoid duplicating existing tags
                                dataInput.forEach((item) => {
                                    // Copy values for Revenue and Cost with the updated index
                                    item[`Revenue_${index + 1}`] = item['Revenue_1'];
                                    item[`Cost_${index + 1}`] = item['Cost_1'];
                                });
                            }
                            //console.log(dataInput);
                            inputDataValue = dataInput
                        }
                        else {
                            inputDataValue = res
                        }
                        setInputData(inputDataValue)
                    }
                }

                setloading(true)
                const initialClass = Object.keys(tableNames)[0];
                handleTabChange(initialClass)

            }
            // data['CashFlowData'] = res
            // await AddOrUpdateCashFlowProject(data)
            // setloading(false)
        })();
    }, []);

    //
    function transformData(exp, rev) {
        const result = [];

        // Iterate over entries of the expenditure object
        Object.entries(exp).forEach(([key, expenditureValue]) => {
            // Only process valid date keys
            if (/^\d{2}-[a-zA-Z]{3}-\d{4}$/.test(key)) {
                const monthName = key.slice(3);
                const expenditure = parseInt(expenditureValue) || 0;
                const cashIn = parseInt(rev[key]) || 0;
                const netCash = cashIn - expenditure;

                result.push({
                    monthName,
                    expenditure: expenditure,
                    cashIn: cashIn,
                    netCash: netCash,
                });
            }
        });

        return result;
    }

    useEffect(() => {
        if (cashReceipts.length) {
            const exp = expenditureData[expenditureData.length - 1];
            const rev = cashReceipts[cashReceipts.length - 2];
            const finalArray = transformData(exp, rev);
            //
            const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const sortedData = finalArray.sort((a, b) => {
                const [monthA, yearA] = a.monthName.split('-');
                const [monthB, yearB] = b.monthName.split('-');
                // Compare by year first, then by month
                if (yearA !== yearB) {
                    return yearA - yearB;
                }
                return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
            });
            setDashBoardCashReceipts(
                {
                    title: {
                        // text: "CRC Evans cashflow by Product Category ",
                        text: "Cumulative cashflow in U.S. Dollars ",
                    },
                    // subtitle: {
                    //     text: "Cumulative cashflow in U.S. Dollars",
                    // },
                    data: sortedData,
                    series: [
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "expenditure",
                            yName: "Total Expenditure in $",
                        },
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "cashIn",
                            yName: "Total Cash Received in $",
                        },
                        {
                            type: "line",
                            xKey: "monthName",
                            yKey: "netCash",
                            yName: "Total Cash Flow in $",
                        },
                    ],
                    axes: [
                        {
                            type: 'category',
                            position: 'bottom',
                        },
                        {
                            type: 'number',
                            position: 'left',
                            label: {
                                formatter: (params) => {
                                    return params.value.toLocaleString('en-US');
                                },
                            },
                        },
                    ],
                    background: {
                        fill: "aliceblue",
                    },
                }
            )
        }
        if (expenditureData.length) {
            const exp = expenditureData[expenditureData.length - 1];
            const rev = cashReceipts[cashReceipts.length - 2];
            const finalArray = transformData(exp, rev);
            //
            const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const sortedData = finalArray.sort((a, b) => {
                const [monthA, yearA] = a.monthName.split('-');
                const [monthB, yearB] = b.monthName.split('-');
                // Compare by year first, then by month
                if (yearA !== yearB) {
                    return yearA - yearB;
                }
                return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
            });
            setDashBoardExpenditureData(
                {
                    title: {
                        // text: "CRC Evans Expenditure & Cash In by Product Category ",
                        text: "Total Expenditure & Cash In in U.S. Dollars",
                    },
                    // subtitle: {
                    //     text: "Total Expenditure & Cash In in U.S. Dollars",
                    // },
                    data: sortedData,
                    series: [
                        {
                            type: "bar",
                            xKey: "monthName",
                            yKey: "expenditure",
                            yName: "Total Expenditure in $",
                        },
                        {
                            type: "bar",
                            xKey: "monthName",
                            yKey: "cashIn",
                            yName: "Total Cash Received in $",
                        },
                    ],
                    axes: [
                        {
                            type: 'category',
                            position: 'bottom',
                        },
                        {
                            type: 'number',
                            position: 'left',
                            label: {
                                formatter: (params) => {
                                    return params.value.toLocaleString('en-US');
                                },
                            },
                        },
                    ],
                    // background: {
                    //     fill: 'transparent', // Set your desired background color
                    // },
                }
            )
        }
    }, [cashReceipts, expenditureData]);




    const reorderObjectExpenture = async (obj, key) => {
        // Static keys in the desired order
        let staticKeys = ["Category", "Order", "Supplier Payment Terms", "Cash Out", 'Cost', "Total Cost USD"];
        if (key == 'cash_receipts')
            staticKeys = ["Category", "Approved Invoice", "Payment Due", "Milestones", , "Cash In", "Total Cost USD"];

        // Extract date-like keys dynamically using regex
        const dateKeys = Object.keys(obj).filter(key => /\d{2}-[A-Za-z]{3}-\d{4}/.test(key));

        // Sort the date keys chronologically
        dateKeys.sort((a, b) => new Date(a) - new Date(b));
        const sliceV = key == 'cash_receipts' ? 6 : 3
        // Combine the static keys and sorted date keys
        const orderedKeys = [...staticKeys.slice(0, sliceV), ...dateKeys, ...staticKeys.slice(sliceV)];

        // Create a new object with the desired key order
        const reorderedObj = {};
        orderedKeys.forEach((key) => {
            if (key in obj) {
                reorderedObj[key] = obj[key];
            }
        });

        return reorderedObj;
    };

    const generateColumns = async (columnList, tapName) => {


        if (tapName == 'expenditure' || tapName === 'cash_receipts') {
            columnList = await reorderObjectExpenture(columnList, tapName)
        }
        //console.log(columnList)
        const columnDefs = Object.keys(columnList)
            .filter(key => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'ID') // Exclude unwanted keys
            .map((key, index) => ({
                field: key,
                headerName: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
                key: key, // Use the key directly

                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                    values: ["Equipment", "Welding Wire", "Consumables & Spares", "Shipping Mob/Demob", "PM", "Commission"],
                },


                valueFormatter: (params) => {
                    if (tapName == 'expenditure' || tapName === 'cash_receipts') {


                        // Check if the column is a date column
                        const isDateColumn = ['Approved Invoice', 'Payment Due'].includes(key);

                        if (isDateColumn) {
                            // Validate and format the date
                            const date = moment(params.value); // Parse date with moment.js
                            return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                        }
                        else if (['Milestones'].includes(key)) {
                            const foramteValue = Math.floor(Number(params.value)).toString();
                            return `${foramteValue}%`
                        }

                        else {

                            const isDateColumn1 = ['Category', 'Order', 'Supplier Payment Terms', 'Payment_Due', 'Approved_Invoice'].includes(key);

                            if (!isDateColumn1) {
                                const foramteValue = Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return `$${foramteValue}`

                            }
                        }
                        return params.value;
                    }
                    else if (tapName == 'INPUT') {
                        if (['Revenue_1', 'Cost_1'].includes(key)) {
                            const foramteValue = Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return `$${foramteValue}`

                        }
                    }


                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: { textAlign: 'left' }, // Cell alignment
                //editable: true, // Enable editing for cells
                //filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));

        // Debugging output for generated columns
        //console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };



    const fetchData = async (data, key) => {
        console.log(data.length)
        if (data.length > 0) {


            generateColumns(data[0], key);
            setRowData(data);

            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };

    const convertToAssumptionArray = (inputObj) => {
        console.log(inputObj)
        // Map each key-value pair into the desired object format, excluding certain keys
        return Object.entries(inputObj)
            .filter(([key]) => key !== 'totalCount' && key !== 'CashFlowData' && key !== 'InputProjectData' && key !== 'ID') // Filter out unwanted keys
            .map(([key, value]) => ({
                Assumption_Key: key.replace(/_/g, ' '), // Replace underscores with spaces
                Value: value
            }));
    };



    const handleTabChange = async (key) => {

        console.log(key)
        setloading(true)


        setActiveKeyLine(key);
        //setRowData([])
        // if (key === 'INPUT') {

        //     setloadingData(true);
        //     setColumnDefs(null);
        //     setRowData(null)
        //     await fetchData(inputData, key)

        //     setloadingData(false);
        // }
        if (key === 'expenditure' && expenditureData.length > 0) {
            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)

            await fetchData(expenditureData, key)
            setloadingData(false);

        }
        else if (key === 'cash_receipts' && cashReceipts.length > 0) {
            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)

            await fetchData(cashReceipts, key)
            setloadingData(false);

        }
        else if (key === 'KEY_ASSUMPTION' && projectDetails) {
            const data = convertToAssumptionArray(projectDetails)

            setloadingData(true);
            setColumnDefs(null);
            setRowData(null)

            await fetchData(data, key)
            setloadingData(false);

        }

        setSelectedMatrialClass(key);
        setloading(false)
    }


    // useEffect(() => {
    //     console.log('rawDataList updated:', rawDataList.length);

    //     if (rawDataList[activeKeyLine])
    //         fetchData();
    // }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: false,
            enableRowGroup: false,
            enablePivot: false,
            filter: true,
        };
    }, []);


    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log("params", params.api)
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawListLoading, loadingData, activeKeyLine]);



    useEffect(() => {
        //console.log(activeKeyLine)
        if (gridRef.current) {
            console.log(activeKeyLine)
            if (loadingData) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loadingData, activeKeyLine])

    //

    useEffect(() => {
        if (isRawListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (isRawListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);


    const OnGenerateCashFlow = async () => {
        const data = {
            'Project_details': projectDetails,
            Inputs: inputData
        }
        console.log(data)

        const response = await generateEMEAASales(data)
        console.log(response)
        projectDetails['CashFlowData'] = response
        projectDetails['InputProjectData'] = inputData
        if (projectDetails['CashFlowData'].expenditure)
            setExpenditureData(projectDetails['CashFlowData'].expenditure)
        if (projectDetails['CashFlowData'].cash_receipts)
            setCashReceipts(projectDetails['CashFlowData'].cash_receipts)

        const res = await AddOrUpdateCashFlowProject(formData)
        if (res && res.length > 0) {
            console.log(res)
            setProjectDetails(res[0])
        }

    }

    // Custom status panel component
    const CustomStatusPanel = () => {
        return (
            <>

                <div style={{ paddingTop: '10px' }}>
                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '-10px', marginBottom: '-20px' }}
                        onClick={async () => await addEmptyRow()} disabled={activeKeyLine == 'INPUT' ? false : true}
                    >
                        Add New
                    </Button>

                    <Button shape="round" key="apply" type="primary" style={{ paddingLeft: '10px', marginBottom: '-20px' }}
                        onClick={async () => await OnGenerateCashFlow()} disabled={activeKeyLine == 'INPUT' ? false : true}
                    >
                        Generate Cash Flow
                    </Button>

                </div>

            </>
        )

    };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            // {
            //     statusPanel: () => <CustomStatusPanel />, // React component
            //     align: 'right',
            // },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);




    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    const onChange = (key) => {
        setCurrentSection(key);
    };


    const getKeyAssumption = () => {


        const setAdddnewItem = (event) => {
            // setNewItem(event.target.value);
        };


        const addItem = async (type, newItem) => {
            // console.log(type, newItem, categoryListdata)
            if (newItem) {

                // const newItem1 = { [tableNames[type]]: newItem }; // Example item
                // addItemToCategoryList(type, newItem1);
                // const obj = {
                //     tableName: type,
                //     data: [{
                //         [tableNames[type]]: newItem
                //     }]
                // };
                // setNewItem('')
                // console.log(obj);
                // Uncomment and implement this function
                // await AddOrUpdateMasterData(obj)

                // const category = await getCategoryListFromDatabse();
                // if (category)
                //     setCategoryListdata(category)
                // console.log(category)
            }


        };

        const removeItem = (e) => {
            e.preventDefault();
            // setNewItem('');
        }

        const handleInputChange = (name, value) => {

            if (name === 'Expected_signing_date' || name == 'Start_of_job' || name === 'Leaves_port' || name === 'Arrives_port' ||

                name === 'Production_start' || name === 'Production_end' || name === 'welding_wire_order'
            )
                value = moment(value).format('YYYY-MM-DD');
            console.log(name, value)

            setFormData((prevData) => ({ ...prevData, [name]: value }));

        };

        const handleCancel = () => {
            projectDetailForm.resetFields();
            // setIsEdit(false)

            setFormData({})
            //onClose();
            //setIsModalVisible(false)

        };


        const getCategoryValue1 = (type) => {
            if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
                return formData[type]; // Return the value for the category
            }
        };

        const handleOk = () => {
            setProjectDetails(formData)
        }

        return (
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                //onValuesChange={onValuesChange}
                width={1200}
            >
                 <Row gutter={16} >
                
                                    <Col lg={5}>
                                        <Form.Item name="Name" label="Project Name" required>
                                            <div>
                                                <Input onChange={(e) => handleInputChange('Name', e.target.value)} size="large"
                                                    value={getCategoryValue1('Name')} />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                
                
                                    <Col lg={5}>
                                        <Form.Item name="Country" label="Country">
                                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                                onChange={(value) => handleInputChange('Country', value)} size="large"
                                                showSearch
                                                allowClear
                
                                                dropdownRender={menu => (
                                                    <>
                                                        {menu}
                                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                                            <Typography.Link onClick={(e) => addItem('Countries_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                                <CheckOutlined />
                                                            </Typography.Link>
                                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                                <CloseOutlined />
                                                            </Typography.Link>
                                                        </Space>
                                                    </>
                                                )}
                
                                            >
                                                {countries_List &&
                                                    countries_List
                                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                                        .map((item, i) => (
                                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                                {item.country}
                                                            </Option>
                                                        ))
                                                }
                
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item name="Expected_signing_date" label="Expected Signing Date" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Expected_signing_date'])}
                                                    onChange={(date) => handleInputChange('Expected_signing_date', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item name="Start_of_job" label="Start of Job" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Start_of_job'])}
                                                    onChange={(date) => handleInputChange('Start_of_job', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item name="Completion_of_PQT" label="Completion of PQT" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Completion_of_PQT'])}
                                                    onChange={(date) => handleInputChange('Completion_of_PQT', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={24}>
                                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                                            <div className='heatp-bottom-border'>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </Col>
                
                
                
                                    <Col lg={5}>
                                        <Form.Item name="Arrives_port" label="Arrives Port" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Arrives_port'])}
                                                    onChange={(date) => handleInputChange('Arrives_port', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                
                                        </Form.Item>
                                    </Col>
                
                
                
                                    <Col lg={5}>
                                        <Form.Item name="Leaves_port" label="Leaves Port" required>
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Leaves_port'])}
                                                    onChange={(date) => handleInputChange('Leaves_port', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item name="Production_start" label="Production Start" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Production_start'])}
                                                    onChange={(date) => handleInputChange('Production_start', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item name="Production_end" label="Production End" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Production_end'])}
                                                    onChange={(date) => handleInputChange('Production_end', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item name="welding_wire_order" label="welding Wire Order" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['welding_wire_order'])}
                                                    onChange={(date) => handleInputChange('welding_wire_order', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item name="Welding_wire_shipment" label="Welding Wire Shipment" >
                                            <div>
                                                <DatePicker
                                                    value={moment(formData['Welding_wire_shipment'])}
                                                    onChange={(date) => handleInputChange('Welding_wire_shipment', date)}
                                                    format="DD-MMM-YYYY"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Payment Terms" name="Payment_terms">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='days' className='Data360-addafter'
                                                    onChange={(e) => handleInputChange('Payment_terms', Number(e.target.value))}
                                                    value={getCategoryValue1('Payment_terms')}
                                                    style={{ width: '100%', textAlign: 'left' }}
                                                    min={0}
                                                    type="number"
                                                    size="large"
                                                />
                
                                            </div>
                
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Senior Technicians" name="Senior_technicians">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                
                                                <Input addonAfter='hr' className='Data360-addafter'
                                                    onChange={(e) => handleInputChange('Senior_technicians', Number(e.target.value))}
                                                    value={getCategoryValue1('Senior_technicians')}
                                                    min={0}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Lead Technicians" name="Lead_technicians">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                
                                                <Input addonAfter='hr' className='Data360-addafter'
                                                    onChange={(e) => handleInputChange('Lead_technicians', Number(e.target.value))}
                                                    value={getCategoryValue1('Lead_technicians')}
                                                    min={0}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Pipelines Count" name="Pipelines_count">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input onChange={(e) => handleInputChange('Pipelines_count', Number(e.target.value))}
                                                    value={getCategoryValue1('Pipelines_count')}
                                                    style={{ width: '100%', textAlign: 'left' }}
                                                    min={0}
                                                    type="number"
                                                    size="large"
                                                />
                
                                            </div>
                
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={24}>
                                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                                            <div className='heatp-bottom-border'>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="PG Required of Contract" name="PG_required_of_contract">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('PG_required_of_contract')}
                                                    onChange={(e) => handleInputChange('PG_required_of_contract', Number(e.target.value))}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Commission" name="Commission">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('Commission')}
                                                    onChange={(e) => handleInputChange('Commission', Number(e.target.value))}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="WHT" name="WHT">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('WHT')}
                                                    onChange={(e) => handleInputChange('WHT', Number(e.target.value))}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                
                                    <Col lg={5}>
                                        <Form.Item label="AP" name="AP">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('AP')}
                                                    onChange={(e) => handleInputChange('AP', Number(e.target.value))}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Item label="Advance Payment Terms" name="Advance_payment_terms">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('Advance_payment_terms') * 100}
                                                    onChange={(e) => handleInputChange('Advance_payment_terms', Number(e.target.value) / 100)}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="Arrival at Port Payment Terms" name="Arrival_at_port_payment_terms">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('Arrival_at_port_payment_terms') * 100}
                                                    onChange={(e) => handleInputChange('Arrival_at_port_payment_terms', Number(e.target.value) / 100)}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="Completion PQT Payment Terms" name="Completion_of_PQT_payment_terms">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input addonAfter='%' className='Data360-addafter'
                                                    value={getCategoryValue1('Completion_of_PQT_payment_terms') * 100}
                                                    onChange={(e) => handleInputChange('Completion_of_PQT_payment_terms', Number(e.target.value) / 100)}
                                                    min={1}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={24}>
                                        <div style={{ marginBottom: '10px', marginTop: '-25px' }}>
                                            <div className='heatp-bottom-border'>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="Tech Rate" name="Tech_rate">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                
                                                <Input addonBefore='$' className='Data360-addbefore'
                                                    onChange={(e) => handleInputChange('Tech_rate', Number(e.target.value))}
                                                    value={getCategoryValue1('Tech_rate')}
                                                    min={0}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                    <Col lg={5}>
                                        <Form.Item label="Senior Technicians Rev" name="Senior_technicians_rev">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                
                                                <Input addonBefore='$' className='Data360-addbefore'
                                                    onChange={(e) => handleInputChange('Senior_technicians_rev', Number(e.target.value))}
                                                    value={getCategoryValue1('Senior_technicians_rev')}
                                                    min={0}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                
                                    <Col lg={5}>
                                        <Form.Item name="Lead_technicians_rev" label="Lead Technicians Rev">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                
                                                <Input addonBefore='$' className='Data360-addbefore'
                                                    onChange={(e) => handleInputChange('Lead_technicians_rev', Number(e.target.value))}
                                                    value={getCategoryValue1('Lead_technicians_rev')}
                                                    min={0}
                                                    type='number'
                                                    style={{ width: '100%', textAlign: 'left' }} size='large'
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                
                                </Row>
            </Form>
        )
    }


    function onCellValueChanged(event) {
        // console.log(
        //     "onCellValueChanged: " + event.colDef.field + " = " + event.newValue,
        // );
        // console.log(
        //     event
        // );

        if (activeKeyLine === 'INPUT' && event.data) {
            // AddOrUpdateInputProject(event.data)
        }
    }

    // function onRowValueChanged(event) {
    //     const data = event.data;
    //     console.log(
    //         "onRowValueChanged: (" +
    //         data.make +
    //         ", " +
    //         data.model +
    //         ", " +
    //         data.price +
    //         ", " +
    //         data.field5 +
    //         ")",
    //     );
    // }

    // Function to Add an Empty Row
    const addEmptyRow = async () => {

        const newRow = {
            'ID': gridRef.current.api.getDisplayedRowCount() + 1, // Get index of the last row,
            "Section": "",
            "Category": "",
            "Type": "",
            "Revenue_1": 0,
            "Cost_1": 0
        }; // Define the empty row
        console.log('Emty Row', newRow)
        const transaction = { add: [newRow] }; // Create a row transaction
        gridRef.current.api.applyTransaction(transaction); // Apply the transaction
        inputData.push(newRow)
        setInputData(inputData)
        setRowData(inputData)
        gridApi.showLoadingOverlay();
        // Optionally, scroll to the new row
        setTimeout(() => {
            const rowIndex = gridRef.current.api.getDisplayedRowCount() - 1; // Get index of the last row
            gridRef.current.api.ensureIndexVisible(rowIndex, "bottom"); // Scroll to the new row
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 50);


    }

    const removeRowById = async (targetId) => {
        // Ensure gridApi and gridRef exist
        if (!gridApi || !gridRef.current) {
            console.error("Grid API or reference is not available.");
            return;
        }

        // Clone the inputData array to avoid direct mutation
        const updatedData = [...inputData];
        console.log(updatedData)
        // Find the index of the row with the target ID
        const index = updatedData.findIndex(row => row.ID === targetId);

        // Check if the ID exists in the data
        if (index === -1) {
            console.warn(`Row with ID ${targetId} not found.`);
            return;
        }

        // Remove the row at the found index
        updatedData.splice(index, 1);

        // Reorder IDs sequentially
        const reorderedData = updatedData.map((row, idx) => ({
            ...row,
            id: idx + 1, // Assign sequential IDs starting from 1
        }));

        // Update the state with the new data
        setInputData(reorderedData);
        setRowData(reorderedData);

        // Refresh the grid
        gridApi.showLoadingOverlay();
        setTimeout(() => {
            //gridRef.current.api.setRowData(reorderedData); // Update grid data
            gridApi.hideOverlay();
            gridRef.current.api.refreshCells({ force: true }); // Force a redraw of all cells
        }, 100); // Delay to simulate loading effect
    }

    const [isExpFullScreen, setIsExpFullScreen] = useState(false);
    const [isRevFullScreen, setIsRevFullScreen] = useState(false);

    const toggleRevFullScreen = () => {
        setIsRevFullScreen((prev) => !prev);
    };
    const toggleExpFullScreen = () => {
        setIsExpFullScreen((prev) => !prev);
    };


    const getPipelineTabpanes = () => {


        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>
                <Tabs activeKey={activeKeyLine} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames)
                        .filter(key =>
                            (key !== 'expenditure' || (key === 'expenditure' && expenditureData?.length > 0)) &&
                            (key !== 'cash_receipts' || (key === 'cash_receipts' && cashReceipts?.length > 0)) &&
                            (key !== 'dashBoard' || (key === 'dashBoard' && cashReceipts?.length > 0))
                        )
                        .map((row) => (
                            <TabPane tab={tableNames[row]} key={row}>
                                {row !== 'dashBoard' ?
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            {row === 'INPUT' && row != 'KEY_ASSUMPTION' ?
                                                <ProjectsInputs
                                                    setProjectDetails={setProjectDetails}
                                                    projectDetails={projectDetails}
                                                    inputData={inputData}
                                                    setInputData={setInputData}
                                                    OnGenerateCashFlow={OnGenerateCashFlow}
                                                ></ProjectsInputs> 


                                                // projectDetails && row == 'KEY_ASSUMPTION' && row !== 'INPUT' ?
                                              
                                                //     <ProjectDetailsAgGrid
                                                //         setProjectDetails={setProjectDetails}
                                                //         projectDetails={projectDetails}>

                                                //     </ProjectDetailsAgGrid> 
                                                    :

                                                    <div style={{ height: 'calc(100vh - 185px)', overflow: 'auto', position: 'relative' }}>
                                                        <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                                                            <AgGridReact
                                                                ref={gridRef}
                                                                rowData={rowData}
                                                                columnDefs={columnDefs}
                                                                rowDragManaged={true}
                                                                onRowDragEnd={onRowDragEnd}
                                                                defaultColDef={defaultColDef}
                                                                rowSelection={rowSelection}
                                                                onGridReady={onGridReady}
                                                                sideBar={false}
                                                                hiddenByDefault={true}
                                                                pivotMode={false}

                                                                pivotPanelShow={"never"}
                                                                autoGroupColumnDef={autoGroupColumnDef}
                                                                animateRows={true}
                                                                onSelectionChanged={onSelectionChanged}
                                                                enableFilter={true}
                                                                statusBar={statusBar}
                                                                rowHeight={40}
                                                                headerHeight={50}
                                                                defaultExcelExportParams={defaultExcelExportParams}
                                                                defaultCsvExportParams={defaultCsvExportParams}
                                                                loadingOverlayComponent={CustomLoadingCellRenderer}
                                                                loadingOverlayComponentParams={{
                                                                    loadingMessage: "Loading all rows...",
                                                                }}
                                                            // onCellValueChanged={onCellValueChanged}

                                                            />

                                                        </div>
                                                    </div>
                                            }


                                            {/* {row == 'KEY_ASSUMPTION' &&
                                            getKeyAssumption()
                                        } */}
                                        </Col>
                                    </Row>
                                    :
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <div className='chart-dashboard'>
                                                <div className={`chart-container ${isExpFullScreen ? "full-screen" : ""}`}>
                                                    <button onClick={toggleExpFullScreen} className={` ${!isRevFullScreen ? "toggle-button" : ""}`}>
                                                        {isExpFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
                                                    </button>
                                                    <AgCharts options={dashBoardExpenditureData} className={`chart-container ${isExpFullScreen ? "full-width" : ""}`} />
                                                </div>
                                                <hr />
                                                <div className={`chart-container ${isRevFullScreen ? "full-screen" : ""}`}>
                                                    <button onClick={toggleRevFullScreen} className={`${!isExpFullScreen ? "toggle-button" : ""}`}>
                                                        {isRevFullScreen ? <ShrinkOutlined /> : <FullscreenOutlined />}
                                                    </button>
                                                    <AgCharts options={dashBoardCashReceipts} className={`chart-container ${isRevFullScreen ? "full-width" : ""}`} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        ))}
                </Tabs>
            </div>
        );
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        if (activeKeyLine == 'INPUT' && selectedData) {
            setIsDeleteLoading(true)
            // const obj = {
            //     columnName: 'Project_Inputs',
            //     tableName: 'Project_Inputs',
            //     data: selectedData
            // };
            // console.log(obj);
            // await DeleteDetails(obj)
            await removeRowById(selectedData.ID)
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);


        }

    }
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }




    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setShowPipelineModel(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    Cash Flow
                </Title>

                <div className="new-calibration" style={{ display: 'flex', alignItems: 'center' }}>

                    {/* <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ marginRight: '5px' }}>
                            <svg style={{ width: 'auto', height: 'auto' }}>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>
                    <Button shape="round" key="apply" type="primary" onClick={() => createExcelTemplate()} style={{ marginLeft: '20px' }} loading={downloadLoading}>
                        Download As Excel
                    </Button>

                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                        onClick={showBulkUploadModal}
                    >
                        <CloudUploadOutlined style={{ fontSize: '20px' }}> </CloudUploadOutlined>
                        Bulk Upload
                    </Button> */}
                </div>

            </div>

            {getPipelineTabpanes()}

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

        </div>

    );


};
const mapStateToProps = ({ FinanceMappingReducer }) => {
    const isRawListLoading = get(FinanceMappingReducer, 'isFetchLoading', false);
    const rawDataList = get(FinanceMappingReducer, `rawDataList`, {}); // Fetch specific table data

    return {
        isRawListLoading,
        rawDataList,
    };
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTaps);