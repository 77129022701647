import { FETCH_RAW_LIST, FETCH_RAW_LIST_FAILURE, FETCH_RAW_LIST_SUCCESS } from "./types";



const fetchRawList = (payload) => ({
  type: FETCH_RAW_LIST,
  ...payload
});
const fetchRawListSuccess = (payload) => ({
  type: FETCH_RAW_LIST_SUCCESS,
  ...payload
});
const fetchRawListFailure = () => ({
  type: FETCH_RAW_LIST_FAILURE
});


export {
  fetchRawList, fetchRawListSuccess, fetchRawListFailure
};