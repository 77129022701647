import get from 'lodash/get';
import { FETCH_RAW_ZPALGLOBAL_LIST, FETCH_RAW_ZPALGLOBALLIST_SUCCESS, FETCH_RAW_ZPALGLOBAL_FAILURE, FETCH_TRANSFORM_LIST, FETCH_TRANSFORM_LIST_SUCCESS, FETCH_TRANSFORM_LIST_FAILURE } from './types';

const initialState = {

    rawZpalglobalList: [],
    isZpalGlobalListLoading: false,
    rawTransformList: [],
    isTransformListLoading: false,

};

const assetUtilizationReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_RAW_ZPALGLOBAL_LIST: {
            return {
                ...state,
                isZpalGlobalListLoading: true
            }
        }

        case FETCH_RAW_ZPALGLOBALLIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rawZpalglobalList: resData,
                isZpalGlobalListLoading: false
            }
        }

        case FETCH_RAW_ZPALGLOBAL_FAILURE: {
            return {
                ...state,
                isZpalGlobalListLoading: false
            }
        }
        case FETCH_TRANSFORM_LIST: {
            return {
                ...state,
                isTransformListLoading: true
            }
        }

        case FETCH_TRANSFORM_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rawTransformList: resData,
                isTransformListLoading: false
            }
        }

        case FETCH_TRANSFORM_LIST_FAILURE: {
            return {
                ...state,
                isTransformListLoading: false
            }
        }
        //

        default: {
            return state;
        }
    }
}

export default assetUtilizationReducer;