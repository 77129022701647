import { FETCH_RAW_LIST, FETCH_RAW_LIST_FAILURE, FETCH_RAW_LIST_SUCCESS } from "./types";





// RAW CM CRC list
const fetchCashflowList = (payload) => ({
  type: FETCH_RAW_LIST,
  ...payload
});

const fetchCashflowListSuccess = (payload) => ({
  type: FETCH_RAW_LIST_SUCCESS,
  ...payload
});

const fetchCashflowListFailure = () => ({
  type: FETCH_RAW_LIST_FAILURE
});


export {

  fetchCashflowList,fetchCashflowListSuccess,fetchCashflowListFailure,

};