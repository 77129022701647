/* eslint-disable linebreak-style */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history.js';

import moment from 'moment';
import { GetRefreshInfo } from '../../redux/AssetUtilization/saga.js';
import UtilizationPageAgGrid from './ZPal_Global.js';
import UtilizationTaps from './UtilizationTaps.js';
import ComingSoon from '../Comingsoon.js';


const { Title, Text } = Typography;


const UtlizationMainPage = (props) => {


    const {


    } = props



    const [refreshInfo, setRefreshInfo] = useState(null);
    const [showRawData, setShowRawData] = useState(false);
    useEffect(() => {

        // fetchInfo('ZPAL_GLOBAL');
    }, []);

    const fetchInfo = async (key) => {
        try {
            const info = await GetRefreshInfo(key);
            if (info.Date_Time) {
                setRefreshInfo(info.Date_Time)
            }
        } catch (error) {
            console.error("Error fetching info:", error);
        }
    };





    return (




        <div className="d-flex flex-column h-100">
            {showRawData ?
                <UtilizationTaps
                    showRawData={showRawData}
                    setShowRawData={setShowRawData}
                    refreshInfo={refreshInfo}
                >

                </UtilizationTaps>

                : <div className="flex-auto">

                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            <div className='back_equip' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={(e) => {
                                        history.push('/SmartLync-HomePage');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                                Asset Utilization
                            </Title>

                            <div className="new-calibration">

                                {/* <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Refresh Date: {refreshInfo ? moment(refreshInfo).format('DD-MMM-YYYY HH:mm') + ' UTC' : ''}</Text> */}
                                <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                    // onClick={showMasterModal}
                                    onClick={() => {
                                        setShowRawData(true)
                                    }}
                                >
                                    Raw Data
                                </Button>
                            </div>


                        </div>


                        <div className="custom-line" />

                        <ComingSoon></ComingSoon>

                    </div>

                </div>
            }
        </div>

    )
};
const mapStateToProps = ({ }) => {

    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UtlizationMainPage);
