
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import { fetchRawListFailure, fetchRawListSuccess, fetchTransformListFailure, fetchTransformListSuccess, fetchZPALGLOBALListFailure, fetchZPALGLOBALListSuccess } from './action';
import { FETCH_RAW_LIST, FETCH_RAW_ZPALGLOBAL_LIST, FETCH_TRANSFORM_LIST } from './types';
import axiosConfig from './axiosConfig';

const fetchDataPage = (tableName, pageNum, pageSize) => {
  return axiosConfig.get(`/GeProcesstList?Table_Name=${tableName}&pageNumber=${pageNum}&pageSize=${pageSize}`);
};


/** Get all raw crc pm list */
function* fetchRawList({ tableName, pageNumber, pageSize, searchKey, sorting }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchKey && !isEmptyObject(searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(searchKey))}`);
    }

    if (Array.isArray(sorting) && sorting.length > 0) {
      sorting.forEach(item => {
          const sortDirection = typeof item.sort === 'string' ? item.sort.toUpperCase() : '';
          queryParams.push(`${item.colId}=${sortDirection}`);
      });
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    
    if (res.status === 200) {
      let primaryData = get(res, ['data', 'data'], []);
      const totalCount = primaryData[0]?.totalCount || 0;
      const totalPages = Math.ceil(totalCount / pageSize);

      // Fetch additional pages if available
      if (totalPages > 1) {
        const additionalDataRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          call(fetchDataPage,tableName, i + 2, pageSize)
        );
        const additionalResponses = yield all(additionalDataRequests);

        // Merge all data into a single array
        const additionalData = additionalResponses.flatMap((response) => response.data?.data || []);
        primaryData = [...primaryData, ...additionalData];
      }
      yield put(fetchRawListSuccess({ response: primaryData }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRawListFailure({ response: [] }));
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw data list.',
      description: `${error}`,
    });
  }
}



function* financeMappingWatcher() {

  yield takeLatest(FETCH_RAW_LIST, fetchRawList);
}

function* financeMappingSagas() {
  yield all([
    call(financeMappingWatcher),
  ]);
}

export default financeMappingSagas;




export const getListFromDatabase = async (tableName) => {
  try {
    let url;
    url = `/GetList?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const AddOrUpdateConfigData = async (data, isNotify = true) => {


  try {
    const res = await axiosConfig.post('/UploadConfig', data);

    notification.destroy();
    // console.log(yield res.json)
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      if (isNotify)
        notification.success({
          message: 'added Successfully.',
          duration: 3,
        });

      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const DeleteMasterDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteMaster', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}

export const GetRecordListExcel = async (tableName) => {
  try {
    let url;
    url = `/GetRecordListExcel?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const GetTableConfigutaion = async (tableName) => {

  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email) {
      let url;
      url = `/GetTableConfig?Table_Name=${(tableName)}&User=${user.email}`;


      const res = await axiosConfig.get(url);
      if (res.status === 403) {
        notification.error({
          message: 'Unauthorized',
        });
        return null;
      } if (res.status === 200) {
        const data = get(res, ['data', 'data'], {});
        //console.log('testType', data)
        return data;
      }
    }
    return [];
  } catch (error) {
    return [];
  }

};

export const GetRefreshInfo = async (tableName) => {
  try {
    let url;
    url = `/GetRefreshInfo?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const SaveLayouts = async (data) => {

  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email) {
     
      const resmaster = await axiosConfig.post('/SaveTableConfig', data);
      //console.log(resmaster)
      if (resmaster.status === 200) {
        notification.success({
          message: 'Layout saved Successfully.',
          duration: 3,
        });
        return true
      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return false
      }
    }
    else {
      notification.success({
        message: 'Invalid User Id.',
        duration: 3,
      });

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Save Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}