/* eslint-disable linebreak-style */

import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography, Row, Col,Card } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history.js';

import moment from 'moment';



const { Title, Text } = Typography;


const EMEAAMainPage = (props) => {


    const {


    } = props


    return (
        <div className="d-flex flex-column h-100">

            {
                <div className="flex-auto">

                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            <div className='back_equip' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={(e) => {
                                        history.push('/SmartLync-HomePage');
                                    }} />
                                </Tooltip>
                            </div>
                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '20px', paddingTop: '5px' }}>
                                EMEAA Sales
                            </Title>

                        </div>

                        


                        <div className="custom-line" />

                        <Row gutter={16} className="detail-rows">

                            <Col span={40}>
                                <Card className='custom-card'
                                    onClick={() => {
                                        history.push('/CashFlow');
                                    }}
                                    style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                    <div style={{ marginTop: '-10px' }} >
                                        <div >
                                            {"Cash Flow"}
                                        </div>
                                        <div style={{ paddingTop: '5px' }}>
                                            <RightCircleOutlined></RightCircleOutlined>
                                        </div>
                                    </div>
                                </Card>
                            </Col>

                        </Row>





                    </div>


                </div>
            }



        </div>

    )
};
const mapStateToProps = ({ }) => {

    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EMEAAMainPage);
