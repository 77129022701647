
import React from 'react';
import { GetTableConfigutaion, SaveLayouts } from "../../redux/AssetUtilization/saga";


export const saveGridLayout = async (gridRef, currentTap, layouts, setLayoutSave) => {
  if (gridRef.current && gridRef.current.api) {
    console.log('before Columns:', gridRef.current.api.getColumnState());
    // Simplify column state
    const columnState = gridRef.current.api.getColumnState().map(({ colId, hide, aggFunc, pinned, width, pivot, enableRowGroup, rowGroup, enablePivot, enableValue }) => ({
      colId,
      hide,
      aggFunc,
      pinned,
      width,
      pivot,
      enableRowGroup,
      rowGroup,
      enablePivot,
      enableValue
    }));

    // Simplify row group state
    const rowGroupState = gridRef.current.api.getRowGroupColumns().map(({ colId }) => ({
      colId,
    }));

    // Simplify pivot state
    const pivotState = gridRef.current.api.getPivotColumns().map(({ colId }) => ({
      colId,
    }));

    // Simplify value state
    const valueState = gridRef.current.api.getValueColumns().map(({ colId, aggFunc, aggregationActive, visible, colDef, userProvidedColDef }) => ({
      colId,
      aggFunc, // Include aggregation function if relevant
      aggregationActive,
      visible,
      colDef,
      userProvidedColDef

    }));
    const filterModel = gridRef.current.api.getFilterModel(); // Get current filter state
    const isPivotMode = gridRef.current.api.isPivotMode()
    //console.log('After Setting Value Columns:', gridRef.current.api.getValueColumns());

    const savedState = {
      columnState,
      rowGroupState,
      pivotState,
      valueState,
      filterModel,
      isPivotMode:isPivotMode
    };
    if (!layouts)
      layouts = {}
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email) {

      const layoutdata = {
        Table_Name: currentTap,
        Layouts: savedState,
        User: user.email
      }
      // Store the cleaned state in localStorage
      //localStorage.setItem('gridLayout', JSON.stringify(savedState));
      const data = {
        key: 'Layouts',
        tableName: 'Grid_Layout',
        data: layoutdata
      }
      console.log('Grid layout saved:', data);
      await SaveLayouts(data)
      setLayoutSave(false)
    }
  }
};


export const restoreGridLayout = async (gridRef, currentTap,setIsPivotMode) => {
  if (gridRef.current && gridRef.current.api) {
    const layouts = await GetTableConfigutaion(currentTap)
    console.log('Saved Grid Layout:', layouts);
    if (layouts && layouts['Layouts']) {

      const savedState = layouts['Layouts']
      //console.log('Saved Grid Layout:', savedState);
      if (savedState && gridRef.current.api) {
      
        const { columnState, rowGroupState, pivotState, valueState, filterModel,isPivotMode } = savedState;
        setIsPivotMode(isPivotMode)

        // Debug: Check if the saved state matches the grid columns
        // console.log('Saved Column State:', columnState);
        // console.log('Saved Row Group State:', rowGroupState);
        // console.log('Saved Pivot State:', pivotState);
        // console.log('Saved Value State:', valueState);

        // Apply column state
        const columnStateApplied = gridRef.current.api.applyColumnState({
          state: columnState,
          applyOrder: true, // Ensure column order is restored
        });
       
        // Debug: Check if column state was successfully applied
        console.log('Column State Applied:', columnStateApplied);

        // Apply row group, pivot, and value columns (if column state applied successfully)
        if (columnStateApplied) {
          if (rowGroupState && rowGroupState.length) {
            gridRef.current.api.setRowGroupColumns(rowGroupState.map(({ colId }) => colId));
          }

          if (pivotState && pivotState.length) {
            gridRef.current.api.setPivotColumns(pivotState.map(({ colId }) => colId));
          }
          if (filterModel) {
            var keys = Object.keys(filterModel);
             if(keys && keys.length > 0)
             gridRef.current.api.setFilterModel(filterModel)
          }

          // if (valueState && valueState.length) {
          //     gridRef.current.api.setValueColumns(
          //         valueState.map(({ colId, aggFunc }) => ({
          //             colId :colId,
          //             aggFunc: aggFunc || null, // Provide a fallback for aggregation functions
          //         }))
          //     );
          // }
          // console.log('valueState Value Columns:', valueState.length);
          if (valueState && valueState.length) {

            const validValueState = valueState
              .filter(({ colId }) => colId) // Ensure colId exists
              .map(({ colId, aggFunc, aggregationActive, visible, colDef, userProvidedColDef }) => ({
                colId,
                aggFunc: aggFunc || 'sum', // Default to null if aggFunc is missing
                aggregationActive: aggregationActive,
                visible,
                colDef,
                userProvidedColDef
              }));

            console.log('Setting Value Columns:', validValueState);

            gridRef.current.api.addValueColumns(validValueState);
            gridRef.current.api.refreshClientSideRowModel('aggregate');
            // Restore pivot mode after loading
            
            // console.log('Value columns set and row model refreshed.');
            // console.log('Is Pivot Mode Enabled:', gridRef.current.api.isPivotMode());
          }

          gridRef.current.api.hideOverlay();

          console.log('Grid layout restored successfully.');
        } else {
          console.error('Failed to apply column state.');
        }
      } else {
        console.log('No saved layout found in localStorage.');
      }
    }
    else {
      console.log('No saved layout found in Database.');
    }
  }
};
export const CustomLoadingCellRenderer = ({ loadingMessage }) => {
  return (
    <div style={{ textAlign: 'center', padding: '10px', color: '#888' }}>
      <div className="spinner" style={{ marginBottom: '8px' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      <span>{loadingMessage}</span>
    </div>
  );
};

