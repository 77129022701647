import get from 'lodash/get';
import { FETCH_RAW_LIST, FETCH_RAW_LIST_FAILURE, FETCH_RAW_LIST_SUCCESS } from './types';

const initialState = {
  isFetchLoading: false,
  rawDataList: [], // Store data dynamically by tableName
  tableName: '', // Tracks the currently active table
};

const EMEAAMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RAW_LIST: {
      return {
        ...state,
      
        isFetchLoading: true,
      };
    }

    case FETCH_RAW_LIST_SUCCESS: {
      const resData = get(action, 'response', []);
      return {
        ...state,
        rawDataList:resData,
        isFetchLoading: false,
      };
    }

    case FETCH_RAW_LIST_FAILURE: {
      return {
        ...state,
        isFetchLoading: false, // Simply update the loading state
      };
    }

    default: {
      return state;
    }
  }
};

export default EMEAAMappingReducer;
