

//ZPAL-GLOBAL
export const FETCH_RAW_ZPALGLOBAL_LIST = '@@fetch-raw-zpalglobal-List';
export const FETCH_RAW_ZPALGLOBALLIST_SUCCESS = '@@fetch-raw-zpalglobal-List-success';
export const FETCH_RAW_ZPALGLOBAL_FAILURE = '@@fetch-raw-zpalglobal-List-failure';


//ZPAL-GLOBAL
export const FETCH_TRANSFORM_LIST = '@@fetch-raw-transform-List';
export const FETCH_TRANSFORM_LIST_SUCCESS = '@@fetch-raw-transform-List-success';
export const FETCH_TRANSFORM_LIST_FAILURE = '@@fetch-raw-transform-List-failure';